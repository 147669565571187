import { defineStore } from 'pinia';

export type ConfigurationStoreState = {
    darkMode: boolean;
    tourEnded: boolean;
};

export const useConfigurationStore = defineStore({
    id: 'mv_configuration',
    state: () =>
        ({
            darkMode: false,
            tourEnded: false,
        }) as ConfigurationStoreState,
    getters: {
        isDarkMode: (state: ConfigurationStoreState) => state.darkMode,
        isTourEnded: (state: ConfigurationStoreState) => state.tourEnded,
    },
    actions: {
        changeDarkMode(mode: boolean) {
            this.darkMode = mode;
        },
        changeTourState(finished: boolean) {
            this.tourEnded = finished;
        },
    },
    persist: {
        storage: window.localStorage,
    },
});
