import { LanguageEnum, StateEnum } from '@/core/enums';
import { SurveyFormFieldTypesEnum } from '@/core/enums/survey-form-field-types.enum';
import { MailTemplateTypeEnum } from '@/core/enums/mail-template-type.enum';

export function getLanguagesKeys() {
    return Object.keys(LanguageEnum).filter((e) => !isNaN(Number(e)));
}

export function getMailTemplateTypeKeys() {
    return Object.keys(MailTemplateTypeEnum).filter((e) => !isNaN(Number(e)));
}

export function getLangFlagByEnum(langCode: LanguageEnum | string) {
    if (typeof langCode === 'string') {
        langCode = parseInt(langCode);
    }
    switch (langCode) {
        case LanguageEnum.Catalan:
            return 'ad';
        case LanguageEnum.Spanish:
            return 'es';
        case LanguageEnum.French:
            return 'fr';
        case LanguageEnum.English:
            return 'gb';
        default:
            return 'xxx';
    }
}

export function getStatesKeys() {
    return Object.keys(StateEnum).filter((e) => !isNaN(Number(e)));
}

export function getBadgeStatusByState(state: StateEnum | string): string {
    if (typeof state === 'string') {
        state = parseInt(state);
    }
    switch (state) {
        case StateEnum.Cancelled:
            return 'unqualified';
        case StateEnum.Active:
            return 'new';
        case StateEnum.Pending:
            return 'renewal';
        case StateEnum.Sending:
            return 'proposal';
        case StateEnum.Sent:
            return 'qualified';
        case StateEnum.Closed:
            return 'negotiation';
        default:
            return 'renewal';
    }
}

export function getAvailableFontFamilies() {
    return [
        'Helvetica',
        'Arial',
        'Arial Black',
        'Verdana',
        'Tahoma',
        'Trebuchet MS',
        'Impact',
        'Gill Sans',
        'Times New Roman',
        'Georgia',
        'Palatino',
        'Baskerville',
        'Andalé Mono',
        'Courier',
        'Lucida',
        'Monaco',
        'Bradley Hand',
        'Brush Script MT',
        'Luminari',
        'Comic Sans MS',
    ];
}

export function getSurveyFormFieldTypes() {
    return Object.keys(SurveyFormFieldTypesEnum).filter((e) => isNaN(Number(e)));
}

export function genRandHexColor() {
    return Math.floor(Math.random() * 16777215).toString(16);
}

export function formatNumberCurrency(value, language = 'es-ES', currency = 'EUR'): string {
    return new Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(value);
}

export function formatNumberDecimal(value, language = 'es-ES', decimals = 2): string {
    return new Intl.NumberFormat(language, {
        style: 'decimal',
        maximumFractionDigits: decimals,
    }).format(value);
}

export function formatNumberPercent(value, language = 'es-ES', decimals = 2): string {
    return new Intl.NumberFormat(language, {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(value);
}
