<template>
    <Dialog
        header="Formulario de soporte y mejoras"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        position="bottom"
        :modal="true"
        :visible="true"
        :closable="false"
    >
        <p>Cuentanos que te parece la nueva plataforma.</p>
        <p>Cualquier comentario sera bien recivido y nos ayuda a mejorar. 🙂</p>
        <Divider />
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <label for="firstname2" :class="{ 'p-error': v$.subject.$invalid && submitted }">Assunto:</label>
                <InputText
                    id="firstname2"
                    type="text"
                    v-model="v$.subject.$model"
                    :class="{ 'p-invalid': v$.subject.$invalid && submitted }"
                />
                <small v-if="(v$.subject.$invalid || v$.subject.$pending.$response) && submitted" class="p-error">{{
                    v$.subject.required.$message.replace('Value', 'Subject')
                }}</small>
            </div>
            <div class="field col-12">
                <label for="address">Descripción:</label>
                <Textarea rows="10" :autoResize="true" v-model.trim="description" />
            </div>
        </div>
        <template #footer>
            <Button
                label="Enviar"
                icon="pi pi-send"
                @click="sendSupportMail(!v$.$invalid)"
                class="w-full mb-3"
                :loading="isSending"
                autofocus
            />
            <Button label="Cancelar" icon="pi pi-times" @click="onClickCloseDialog" class="p-button-secondary w-full" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useUsersStore } from '@/store/users/users.store';
import { HttpCodesEnum } from '@/core/enums/http-codes.enum';
export default defineComponent({
    name: 'ContactSupportForm',
    emits: ['click-close-send-comments'],
    setup: () => ({ v$: useVuelidate(), usersStore: useUsersStore() }),
    data: () => ({
        subject: null,
        description: null,
        submitted: false,
        isSending: false,
    }),
    validations() {
        return {
            subject: {
                required,
            },
        };
    },
    methods: {
        onClickCloseDialog() {
            this.$emit('click-close-send-comments');
        },
        async sendSupportMail(isFormValid) {
            this.submitted = true;
            if (!isFormValid) return;
            await this.$recaptchaLoaded();
            const reCaptchaToken = await this.$recaptcha('submit');
            this.isSending = true;
            const _sendResponse = await this.usersStore.sendUserFeedback(
                {
                    subject: this.subject,
                    description: this.description ?? '',
                    url: location.href,
                },
                reCaptchaToken,
            );
            if (_sendResponse.status === HttpCodesEnum.OK) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Comentario enviado',
                    life: 3000,
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error enviando comentario',
                    life: 3000,
                });
            }
            this.resetForm();
            this.onClickCloseDialog();
        },
        resetForm() {
            this.isSending = false;
            this.submitted = false;
            this.subject = null;
            this.description = null;
        },
    },
});
</script>

<style scoped></style>
