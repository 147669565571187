import { defineStore, StoreDefinition } from 'pinia';
import MailCampaignServices from '@/service/mail-campaigns/mail-campaign.services';
import { ICreateMailCampaign } from '@/core/interfaces/mail-campaign/create-mail-campaign.interface';

export type MailCampaignStoreState = {
    mailCampaignSelected: string | null;
    mailCampaignSelectedId: string | null;
    mailCampaignService: MailCampaignServices;
};

export const useMailCampaignStore: StoreDefinition = defineStore({
    id: 'mv_mailCampaign',
    state: () =>
        ({
            mailCampaignSelected: null,
            mailCampaignSelectedId: null,
            mailCampaignService: new MailCampaignServices(),
        }) as MailCampaignStoreState,
    getters: {
        getMailCampaignSelected: (state: MailCampaignStoreState) => state.mailCampaignSelected,
        getMailCampaignSelectedId: (state: MailCampaignStoreState) => state.mailCampaignSelectedId,
    },
    actions: {
        setMailCampaignSelected(mailCampaign: string) {
            this.mailCampaignSelected = mailCampaign;
        },
        setMailCampaignSelectedId(mailCampaignId: string) {
            this.mailCampaignSelectedId = mailCampaignId;
        },
        async getMailCampaigns(page: number, resultsPerPage: number) {
            return await this.mailCampaignService.getMailCampaigns(page, resultsPerPage);
        },
        async getMailCampaign(mailCampaignId: string) {
            return await this.mailCampaignService.getMailCampaign(mailCampaignId);
        },
        async getMailTemplatesFiltered(
            page: number,
            resultsByPage: number,
            language: Array<number>,
            state: Array<number>,
        ) {
            return await this.mailCampaignService.getMailTemplatesFiltered(page, resultsByPage, language, state);
        },
        async createMailCampaign(createMailCampaignObject: ICreateMailCampaign) {
            return await this.mailCampaignService.createMailCampaign(createMailCampaignObject);
        },
    },
});
