<template>
    <div class="layout-topbar">
        <div class="layout-topbar-left">
            <a href="#" class="topbar-menu-button" @click="onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

            <a href="#" class="logo">
                <img :src="'/layout/images/logo-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'" />
            </a>

            <a href="#">
                <img
                    :src="'/layout/images/appname-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                    class="app-name"
                />
            </a>
        </div>

        <AppMenu
            :model="items"
            :menu-mode="menuMode"
            :color-scheme="colorScheme"
            :menu-active="menuActive"
            :active-inline-profile="activeInlineProfile"
            @sidebar-mouse-over="onSidebarMouseOver"
            @sidebar-mouse-leave="onSidebarMouseLeave"
            @toggle-menu="onToggleMenu"
            @change-inlinemenu="onChangeActiveInlineMenu"
            @menu-click="onMenuClick"
            @root-menuitem-click="onRootMenuItemClick"
            @menuitem-click="onMenuItemClick"
            @send-comments-click="onSendCommentsClick"
        />

        <div class="layout-topbar-right">
            <ul class="layout-topbar-right-items">
                <li id="profile" class="profile-item" :class="{ 'active-topmenuitem': topbarMenuActive }">
                    <a href="#" @click="onTopbarItemClick($event, 'profile')">
                        <img class="border-circle" :src="this.getPictureProfile" />
                    </a>

                    <ul class="fadeInDown">
                        <li role="menuitem">
                            <a class="a-day-night" style="display: flex; align-items: center">
                                <i class="pi pi-sun pi-fw"></i>
                                <InputSwitch v-model="isDarkMode" @click.stop="changeLayoutColourMode" />
                                <i class="pi pi-moon pi-fw icon-night"></i>
                            </a>
                        </li>
                        <li role="menuitem" class="hidden">
                            <a href="#" @click="onTopbarSubItemClick($event)">
                                <i class="pi pi-fw pi-user"></i>
                                <span>Profile xxxx appTopbar</span>
                            </a>
                        </li>
                        <li role="menuitem" class="hidden">
                            <a href="#" @click="onTopbarSubItemClick($event)">
                                <i class="pi pi-fw pi-cog"></i>
                                <span>Settings</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="handleLogOut">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="hidden">
                    <a href="#">
                        <i class="topbar-icon pi pi-fw pi-bell"></i>
                        <span class="topbar-badge">2</span>
                        <span class="topbar-item-name">Notifications</span>
                    </a>
                </li>
                <li class="hidden">
                    <a href="#">
                        <i class="topbar-icon pi pi-fw pi-comment"></i>
                        <span class="topbar-badge">5</span>
                        <span class="topbar-item-name">Messages</span>
                    </a>
                </li>
                <li v-tooltip.left="'Enviar comentarios'">
                    <a href="#" @click.prevent="onSendCommentsClick">
                        <i class="topbar-icon pi pi-fw pi-question-circle"></i>
                        <span class="topbar-item-name">Enviar comentarios</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConfigurationStore, useAuthStore } from '@/store';
import AppMenu from '@/layout/AppMenu.vue';

export default defineComponent({
    emits: [
        'menu-click',
        'menuitem-click',
        'root-menuitem-click',
        'menubutton-click',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'toggle-menu',
        'change-inlinemenu',
        'topbar-item-click',
        'change-dark-mode',
        'send-comments-click',
    ],
    setup: () => {
        const configurationStore = useConfigurationStore();
        const authStore = useAuthStore();
        return { configurationStore, authStore };
    },
    data() {
        return {
            activeTopbarItem: null,
            isDarkMode: false,
        };
    },
    props: {
        items: Array,
        menuMode: String,
        colorScheme: String,
        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        menuActive: {
            type: Boolean,
            default: false,
        },
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onMenuClick(event: any) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event: any) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event: any) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event: any) {
            this.$emit('menubutton-click', event);
        },
        onTopbarItemClick(event: any, item: any) {
            this.$emit('topbar-item-click', event, item);
            event.preventDefault();
        },
        onTopbarSubItemClick(event: any) {
            event.preventDefault();
        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        onToggleMenu(event: any) {
            this.$emit('toggle-menu', event);
        },
        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu');
        },
        onSendCommentsClick() {
            this.$emit('send-comments-click');
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        changeLayoutColourMode() {
            this.isDarkMode = !this.isDarkMode;
            this.configurationStore.changeDarkMode(this.isDarkMode);
            if (this.isDarkMode) {
                this.$emit('change-dark-mode', 'teal', 'dark');
            } else {
                this.$emit('change-dark-mode', 'blue', 'light');
            }
        },
        handleLogOut() {
            this.authStore.logOut();
            this.$router.push({ name: 'login' });
        },
    },
    computed: {
        getPictureProfile: function () {
            return this.authStore.getUserPictureProfile;
        },
        getUserName: function () {
            return this.authStore.getUserFullName;
        },
    },
    mounted() {
        this.configurationStore.$subscribe((_, state) => {
            this.isDarkMode = state.darkMode;
        });
        this.isDarkMode = this.configurationStore.isDarkMode;
    },
    components: {
        AppMenu,
    },
});
</script>

<style scoped>
.icon-night {
    margin-left: 0.75rem;
}
.a-day-night {
    background-color: unset !important;
}
</style>
