import axios, { AxiosError, AxiosResponse } from 'axios';

export default class MailEditorServices {
    async generateAuthToken(): Promise<any> {
        return axios
            .post('/external/stripo/auth')
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
