import BookingsServices from '@/service/bookings/bookings.services';
import { defineStore, StoreDefinition } from 'pinia';

export type BookingsStoreState = {
    bookingsService: BookingsServices;
};

export const useBookingsStore: StoreDefinition = defineStore({
    id: 'mv_bookings',
    state: (): BookingsStoreState => ({
        bookingsService: new BookingsServices(),
    }),
    actions: {
        async getBookings(dateStart: string, dateEnd: string) {
            return await this.bookingsService.getBookings(dateStart, dateEnd);
        },
        async getDashboardBookedIndividual() {
            return await this.bookingsService.getDashboardBookedIndividual();
        },
    },
});
