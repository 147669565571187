import axios, { AxiosResponse } from 'axios';
import { StateEnum } from '@/core/enums';
import { IPagination } from '@/core/interfaces/pagination.interface';
import { ICustomTag } from '@/core/interfaces/custom-tag/custom-tag.interface';

export default class CustomTagsServices {
    async getCustomTagsFilteredPaginated(
        page: number,
        resultsPerPage: number,
        establishments?: string[],
        states?: StateEnum[],
        name?: string,
    ): Promise<AxiosResponse<IPagination<ICustomTag>>> {
        return await axios.get(`/custom-tags/filtered`, {
            params: {
                page,
                resultsPerPage,
                ...(establishments && { establishments: establishments }),
                ...(states && { states: states }),
                ...(name && { name: name }),
            },
        });
    }

    async getCustomTag(customTagId: string): Promise<AxiosResponse<ICustomTag>> {
        return await axios.get(`/custom-tags/${customTagId}`);
    }

    async createCustomTag(name: string): Promise<AxiosResponse<ICustomTag>> {
        return await axios.post(`/custom-tags`, {
            name,
        });
    }

    async patchCustomTag(customTagId: string, name: string): Promise<AxiosResponse<ICustomTag>> {
        return await axios.patch(`/custom-tags/${customTagId}`, {
            name,
        });
    }

    async deleteCustomTag(customTagId: string): Promise<AxiosResponse<ICustomTag>> {
        return await axios.delete(`/custom-tags/${customTagId}`);
    }
}
