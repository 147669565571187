import { defineStore, StoreDefinition } from 'pinia';
import MailCampaignStatsServices from '@/service/mail-campaign-stats/mail-campaign-stats.services';

export const useMailCampaignStatsStore: StoreDefinition = defineStore({
    id: 'mv_mailCampaignStats',
    actions: {
        async getGeneralMailgunStatsByTag(mailCampaign: string, mainTag: string, langTag: string, lang: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getGeneralMailgunStatsByTag(mailCampaign, mainTag, langTag, lang);
        },
        async getDeviceMailgunStatsByTag(mailCampaign: string, tag: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getDeviceMailgunStatsByTag(mailCampaign, tag);
        },
        async getProviderMailgunStatsByTag(mailCampaign: string, tag: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getProviderMailgunStatsByTag(mailCampaign, tag);
        },
        async getCountriesMailgunStatsByTag(mailCampaign: string, tag: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getCountriesMailgunStatsByTag(mailCampaign, tag);
        },
        async getByDayGroupedMailgunStats(mailCampaign: string, tag: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getByDayGroupedMailgunStats(mailCampaign, tag);
        },
        async getByHourGroupedMailgunStats(mailCampaign: string, tag: string) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getByHourGroupedMailgunStats(mailCampaign, tag);
        },
        async getMostClickedLinks(tag) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getMostClickedLinks(tag);
        },
        async getMostClickedLinks_V2(tag) {
            const mailCampaignStatsServices = new MailCampaignStatsServices();
            return mailCampaignStatsServices.getMostClickedLinks_V2(tag);
        },
    },
});
