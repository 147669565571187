<template>
    <div
        class="layout-menu-wrapper"
        :class="{ 'layout-sidebar-active': sidebarActive }"
        @click="onMenuClick"
        @mouseover="onSidebarMouseOver"
        @mouseleave="onSidebarMouseLeave"
    >
        <div class="menu-logo">
            <a href="#/dashboard-single" class="logo">
                <img :src="'/layout/images/logo-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'" />
            </a>

            <a href="#/dashboard-single" class="app-name">
                <img :src="'/layout/images/appname-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'" />
            </a>
            <a href="#/dashboard-single" class="menu-pin" @click="onToggleMenu">
                <span v-if="isOverlay()" class="pi pi-times"></span>
                <span v-if="isSidebar() && !sidebarStatic && pinActive" class="pi pi-unlock"></span>
                <span v-if="isSidebar() && sidebarStatic && pinActive" class="pi pi-lock"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu
                class="layout-menu"
                :items="model"
                :menu-mode="menuMode"
                :menu-active="menuActive"
                :root="true"
                :parent-menu-item-active="true"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />
        </div>

        <AppInlineMenu
            :menu-mode="menuMode"
            :active-inline-profile="activeInlineProfile"
            @change-inlinemenu="onChangeActiveInlineMenu"
            @change-dark-mode="onChangeDarkMode"
            @send-comments-click="onSendCommentsClick"
        ></AppInlineMenu>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppSubmenu from '@/layout/AppSubmenu.vue';
import AppInlineMenu from '@/layout/AppInlineMenu.vue';

export default defineComponent({
    name: 'AppMenu',
    emits: [
        'menu-click',
        'menuitem-click',
        'root-menuitem-click',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'toggle-menu',
        'change-inlinemenu',
        'change-dark-mode',
        'send-comments-click',
    ],
    props: {
        model: Array,
        menuMode: String,
        colorScheme: String,
        menuActive: {
            type: Boolean,
            default: false,
        },
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
        sidebarActive: {
            type: Boolean,
            default: false,
        },
        sidebarStatic: {
            type: Boolean,
            default: false,
        },
        pinActive: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppSubmenu,
        AppInlineMenu,
    },
    methods: {
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        onMenuClick(event: any) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event: any) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event: any) {
            this.$emit('root-menuitem-click', event);
        },
        onToggleMenu(event: any) {
            this.$emit('toggle-menu', event);
        },
        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu');
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        onChangeDarkMode(color: string, scheme: string) {
            this.$emit('change-dark-mode', color, scheme);
        },
        onSendCommentsClick() {
            this.$emit('send-comments-click');
        },
    },
});
</script>

<style scoped>
.layout-wrapper.layout-sidebar .layout-menu-wrapper .menu-logo {
    padding-left: 20px;
}
</style>
