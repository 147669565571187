import axios, { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns/format';

export default class RateShopperServices {
    async getPricesBetweenDates(competitiveSet: string, from: Date, to: Date) {
        return axios
            .get(`/competitive-set/${competitiveSet}/prices/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
