<template>
    <div class="col-12 md:col-8 md:col-offset-2" v-if="data?.sections">
        <div
            class="col-12"
            id="header-container"
            :style="{
                color: data.header.style.textColor,
                backgroundColor: data.header.style.backgroundColor,
                fontFamily: data.header.style.typography,
            }"
        >
            <div class="col-12 flex justify-content-between">
                <div class="flex">
                    <img :src="data.header.logo.establishment" class="header-image align-self-center" />
                </div>
                <div class="flex">
                    <img :src="data.header.logo.company" class="header-image align-self-center" />
                </div>
            </div>
            <div class="col-12 flex justify-content-center">
                <h2>{{ data.header.title.find((l) => l.language == selectedLanguage.code).text }}</h2>
            </div>
        </div>
        <div
            class="col-12"
            id="body-container"
            :style="{
                color: data.body.style.textColor,
                backgroundColor: data.body.style.backgroundColor,
                fontFamily: data.body.style.typography,
            }"
        >
            <div class="col-12 flex justify-content-between">
                <div class="flex">
                    <img :src="data.body.logo.establishment" class="header-image align-self-center" />
                </div>
                <div class="flex">
                    <img :src="data.body.logo.company" class="header-image align-self-center" />
                </div>
            </div>
            <div class="col-12 flex justify-content-center">
                <h2>{{ data.body.title.find((l) => l.language == selectedLanguage.code).text }}</h2>
            </div>
            <div class="col-12 flex justify-content-center">
                <SelectButton v-model="selectedLanguage" :options="languages" optionLabel="name">
                    <template #option="prop">
                        <img
                            src="../assets/demo/flags/flag_placeholder.png"
                            :class="'mr-2 flag flag-' + getFlagByLanguageCode(prop.option.code)"
                            width="30"
                        />
                        <span>{{ prop.option.name }}</span>
                    </template>
                </SelectButton>
            </div>
            <!--Paginated -->
            <div v-if="data.paginated">
                <div class="col-12">
                    <div class="flex justify-content-center">
                        <h3>
                            {{
                                data.sections[selectedSection].title.find((l) => l.language == selectedLanguage.code)
                                    .text
                            }}
                        </h3>
                    </div>
                    <div
                        class="mt-2"
                        v-for="kpi in data.kpis.filter((k) => data.sections[selectedSection].kpis.includes(k.kpi))"
                        :key="kpi.kpi"
                    >
                        <div class="flex justify-content-center">
                            <h4>{{ kpi.title.find((l) => l.language == selectedLanguage.code).text }}</h4>
                        </div>
                        <div class="flex justify-content-center">
                            <Textarea
                                v-if="kpi.fieldType == getFieldType('TEXTAREA')"
                                rows="4"
                                cols="50"
                                v-model="kpi.value"
                            ></Textarea>
                            <InputText
                                v-else-if="kpi.fieldType == getFieldType('INPUTTEXT')"
                                v-model="kpi.value"
                            ></InputText>
                            <Rating
                                v-else-if="kpi.fieldType == getFieldType('RATING')"
                                v-model="kpi.value"
                                :cancel="false"
                            ></Rating>
                            <div
                                v-else-if="kpi.fieldType == getFieldType('RADIOBUTTON')"
                                class="flex justify-content-between"
                            >
                                <div class="flex mx-2" v-for="value in kpi.allowedValues" :key="value">
                                    <RadioButton
                                        :name="kpi.kpi.toString()"
                                        :value="value.value"
                                        v-model="kpi.value"
                                    ></RadioButton>
                                    <label :for="kpi.kpi.toString()">{{
                                        value.title.find((l) => l.language == selectedLanguage.code).text
                                    }}</label>
                                </div>
                            </div>
                            <InputNumber
                                v-else-if="kpi.fieldType == getFieldType('INPUTNUMBER')"
                                v-model="kpi.value"
                            ></InputNumber>
                            <InputSwitch
                                v-else-if="kpi.fieldType == getFieldType('INPUTSWITCH')"
                                v-model="kpi.value"
                            ></InputSwitch>
                            <Dropdown
                                v-else-if="kpi.fieldType == getFieldType('DROPDOWN')"
                                :options="
                                    kpi.allowedValues.map((v) => {
                                        return {
                                            text: v.title.find((l) => l.language == selectedLanguage.code).text,
                                            value: v.value,
                                        };
                                    })
                                "
                                v-model="kpi.value"
                                option-label="text"
                                option-value="value"
                            >
                            </Dropdown>
                            <MultiSelect
                                v-else-if="kpi.fieldType == getFieldType('MULTISELECT')"
                                :options="
                                    kpi.allowedValues.map((v) => {
                                        return {
                                            text: v.title.find((l) => l.language == selectedLanguage.code).text,
                                            value: v.value,
                                        };
                                    })
                                "
                                v-model="kpi.value"
                                option-label="text"
                                option-value="value"
                            >
                            </MultiSelect>
                            <div v-else class="">
                                {{ kpi.value }}
                                <Slider v-model="kpi.value" :min="0" :max="100" class="w-15rem" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-content-start">
                    <Button v-if="selectedSection > 0" label="Previous" @click="goBackPage()"></Button>
                </div>
                <div class="flex justify-content-end">
                    <Button
                        v-if="selectedSection < data.sections.length - 1"
                        label="Next"
                        @click="goNextPage()"
                    ></Button>
                    <Button
                        v-else-if="(selectedSection = data.sections.length)"
                        label="Send"
                        @click="sendSurvey()"
                    ></Button>
                </div>
            </div>
            <!-- Not paginated -->
            <div v-else>
                <div class="col-12" v-for="(section, index) in data.sections" :key="section">
                    <div class="flex justify-content-center">
                        <h3>{{ section.title.find((l) => l.language == selectedLanguage.code).text }}</h3>
                    </div>
                    <div
                        class="mt-2"
                        v-for="kpi in data.kpis.filter((k) => data.sections[index].kpis.includes(k.kpi))"
                        :key="kpi.kpi"
                    >
                        <div class="flex justify-content-center">
                            <h4>{{ kpi.title.find((l) => l.language == selectedLanguage.code).text }}</h4>
                        </div>
                        <div class="flex justify-content-center">
                            <Textarea
                                v-if="kpi.fieldType == getFieldType('TEXTAREA')"
                                rows="4"
                                cols="50"
                                v-model="kpi.value"
                            ></Textarea>
                            <InputText
                                v-else-if="kpi.fieldType == getFieldType('INPUTTEXT')"
                                v-model="kpi.value"
                            ></InputText>
                            <Rating
                                v-else-if="kpi.fieldType == getFieldType('RATING')"
                                v-model="kpi.value"
                                :cancel="false"
                            ></Rating>
                            <div
                                v-else-if="kpi.fieldType == getFieldType('RADIOBUTTON')"
                                class="flex justify-content-between"
                            >
                                <div class="flex mx-2" v-for="value in kpi.allowedValues" :key="value">
                                    <RadioButton
                                        :name="kpi.kpi.toString()"
                                        :value="value.value"
                                        v-model="kpi.value"
                                    ></RadioButton>
                                    <label :for="kpi.kpi.toString()">{{
                                        value.title.find((l) => l.language == selectedLanguage.code).text
                                    }}</label>
                                </div>
                            </div>
                            <InputNumber
                                v-else-if="kpi.fieldType == getFieldType('INPUTNUMBER')"
                                v-model="kpi.value"
                            ></InputNumber>
                            <InputSwitch
                                v-else-if="kpi.fieldType == getFieldType('INPUTSWITCH')"
                                v-model="kpi.value"
                            ></InputSwitch>
                            <Dropdown
                                v-else-if="kpi.fieldType == getFieldType('DROPDOWN')"
                                :options="
                                    kpi.allowedValues.map((v) => {
                                        return {
                                            text: v.title.find((l) => l.language == selectedLanguage.code).text,
                                            value: v.value,
                                        };
                                    })
                                "
                                v-model="kpi.value"
                                option-label="text"
                                option-value="value"
                            >
                            </Dropdown>
                            <MultiSelect
                                v-else-if="kpi.fieldType == getFieldType('MULTISELECT')"
                                :options="
                                    kpi.allowedValues.map((v) => {
                                        return {
                                            text: v.title.find((l) => l.language == selectedLanguage.code).text,
                                            value: v.value,
                                        };
                                    })
                                "
                                v-model="kpi.value"
                                option-label="text"
                                option-value="value"
                            >
                            </MultiSelect>
                            <div v-else class="">
                                {{ kpi.value }}
                                <Slider v-model="kpi.value" :min="0" :max="100" class="w-15rem" />
                            </div>
                        </div>
                    </div>
                    <Divider></Divider>
                </div>
                <div class="flex justify-content-center">
                    <Button label="Send" @click="sendSurvey()"></Button>
                </div>
            </div>
        </div>
        <div
            class="col-12"
            id="footer-container"
            :style="{
                color: data.footer.style.textColor,
                backgroundColor: data.footer.style.backgroundColor,
                fontFamily: data.footer.style.typography,
            }"
        >
            <div class="col-12 flex justify-content-between">
                <div class="flex">
                    <img :src="data.footer.logo.establishment" class="header-image align-self-center" />
                </div>
                <div class="flex">
                    <img :src="data.footer.logo.company" class="header-image align-self-center" />
                </div>
            </div>
            <div class="col-12 flex justify-content-center">
                <h2>{{ data.footer.title.find((l) => l.language == selectedLanguage.code).text }}</h2>
            </div>
        </div>
        <Dialog :visible="showThankYou" :closable="false" :modal="true">
            <h2>{{ data.thankYouText.find((l) => l.language == selectedLanguage.code).text }}</h2>
            <template #footer></template>
        </Dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LanguageEnum } from '@/core/enums';
import { FieldTypesEnum } from '@/core/enums/field-types.enum';
import { usePostSurveyStore } from '@/store/post-survey.store';

export default defineComponent({
    name: 'PostSurvey',
    setup: () => {
        const postSurveyStore = usePostSurveyStore();
        return { postSurveyStore };
    },
    data() {
        return {
            selectedLanguage: { name: 'Catalan', code: LanguageEnum.Catalan },
            languages: [],
            selectedSection: 0,
            kpiValues: [],
            showThankYou: false,
            data: {
                kpis: [],
            },
        };
    },
    methods: {
        getFlagByLanguageCode(language: LanguageEnum): string {
            switch (language) {
                case LanguageEnum.Catalan:
                    return 'ad';
                case LanguageEnum.Spanish:
                    return 'es';
                case LanguageEnum.English:
                    return 'gb';
                case LanguageEnum.French:
                    return 'fr';
                default:
                    return '';
            }
        },
        getFieldType(type: string): number {
            switch (type) {
                case 'TEXTAREA':
                    return FieldTypesEnum.TEXTAREA;
                case 'INPUTTEXT':
                    return FieldTypesEnum.INPUT_TEXT;
                case 'RATING':
                    return FieldTypesEnum.RATING;
                case 'RADIOBUTTON':
                    return FieldTypesEnum.RADIOBUTTON;
                case 'INPUTNUMBER':
                    return FieldTypesEnum.INPUT_NUMBER;
                case 'INPUTSWITCH':
                    return FieldTypesEnum.INPUT_SWITCH;
                case 'DROPDOWN':
                    return FieldTypesEnum.DROPDOWN;
                case 'MULTISELECT':
                    return FieldTypesEnum.MULTISELECT;
                case 'SLIDER':
                    return FieldTypesEnum.SLIDER;
            }
            return 0;
        },
        goBackPage() {
            this.selectedSection -= 1;
        },
        goNextPage() {
            this.selectedSection += 1;
        },
        sendSurvey() {
            const surveyId = this.$route.query.s;
            const hmac = this.$route.query.hmac;
            const kpis = this.data.kpis
                .map((k) => {
                    return { kpi: k.kpi, value: k.value };
                })
                .filter((k) => k.value !== undefined);
            this.postSurveyStore.postSurvey(surveyId, hmac, kpis).then((res) => {
                if (res.status == 200) {
                    this.showThankYou = true;
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error sending survey-forms', life: 3000 });
                }
            });
        },
    },
    async mounted() {
        if (this.$route.query.e == undefined || this.$route.query.s == undefined || this.$route.query.hmac == undefined)
            this.$router.push('/login');

        await this.postSurveyStore
            .getSurveyInfo(this.$route.query.s, this.$route.query.hmac, this.$route.query.e)
            .then((res) => {
                if (res.status == 404) {
                    this.$router.push('/not-found');
                } else if (res.status == 401 || res.status == 400) {
                    this.$router.push('/access-denied');
                } else {
                    if (res.data.surveyForm.languages.includes(LanguageEnum.Catalan))
                        this.languages.push({ name: 'Catalan', code: LanguageEnum.Catalan });
                    if (res.data.surveyForm.languages.includes(LanguageEnum.Spanish))
                        this.languages.push({ name: 'Spanish', code: LanguageEnum.Spanish });
                    if (res.data.surveyForm.languages.includes(LanguageEnum.English))
                        this.languages.push({ name: 'English', code: LanguageEnum.English });
                    if (res.data.surveyForm.languages.includes(LanguageEnum.French))
                        this.languages.push({ name: 'French', code: LanguageEnum.French });

                    res.data = res.data.surveyForm;

                    res.data.sections = res.data.sections.filter((s) => s.enabled);
                    res.data.sections = res.data.sections.sort((a, b) => a.order - b.order);

                    res.data.kpis = res.data.kpis.filter((k) => k.enabled);
                    res.data.kpis = res.data.kpis.sort((a, b) => a.order - b.order);

                    this.data = res.data;
                }
            });
    },
});
</script>

<style scoped>
.header-image {
    width: 15rem;
    height: auto;
}
</style>
