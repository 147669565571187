import axios, { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns/format';

export default class BookingStatsServices {
    async getBookingGradesSummaryBetweenDates(date: Date) {
        return axios
            .get(`/booking/grades-summary/${format(date, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingReviewsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/dates/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/count/total/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountPositiveReviewsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/count/positive/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountNegativeReviewsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/count/negative/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountDirectorAnsweredReviewsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/count/director/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByLanguageBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/language/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByMarkBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/mark/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByNightsBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/nights/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByRoomTypeBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/room/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByTravellerTypeBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/traveller/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getBookingAmountReviewsGroupedByCountryBetweenDates(from: Date, to: Date) {
        return axios
            .get(`/booking/review/group/country/${format(from, 'yyyy-MM-dd')}/${format(to, 'yyyy-MM-dd')}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
