import axios, { AxiosError, AxiosResponse } from 'axios';

export default class KpisServices {
    async getKpiById(id: string) {
        return axios
            .get(`/kpi/${id}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getAllKpisPaginated(page: number, resultsByPage: number) {
        return axios
            .get(`/kpi/${page}/${resultsByPage}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async createKpi(kpi: any) {
        return axios
            .post('/kpi', kpi)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async updateKpi(id: string, kpi: any) {
        return axios
            .patch(`/kpi/${id}`, kpi)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async deleteKpi(id: string) {
        return axios
            .delete(`/kpi/${id}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
