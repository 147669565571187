export enum SubModuleEnum {
    BUSINESS_ON_THE_BOOKS = 0,
    BOOKINGS = 1,
    BUDGET = 2,
    RATE_SHOPPER = 3,
    RATE_SHOPPER_COMPETITORS = 4,
    RATE_SHOPPER_COMPETITIVE_SET = 5,
    RATE_SHOPPER_EDITOR = 6,
    METRIGUEST_DASHBOARD = 7,
    METRIGUEST_COMMENT_TIMELINE = 8,
    METRIGUEST_COMMENT_VIEW = 9,
    BOOKING_DASHBOARD = 10,
    BOOKING_COMMENT_TIMELINE = 11,
    AIRBNB_DASHBOARD = 12,
    AIRBNB_COMMENT_TIMELINE = 13,
    GOOGLE_DASHBOARD = 14,
    GOOGLE_COMMENT_TIMELINE = 15,
    EMAIL_EDITOR = 16,
    SURVEY_EDITOR = 17,
    REQUEST_EMAIL_EDITOR = 18,
    THANK_YOU_EMAIL_EDITOR = 19,
    MANAGER_COMMENT_EMAIL_EDITOR = 20,
    TEMPLATES = 21,
    EMAIL_BUILDER = 22,
    CAMPAIGNS = 23,
    CAMPAIGN_EDITOR = 24,
    REPORTS = 25,
    CAMPAIGN_REPORTS = 26,
    MORNING_STATS_SUMMARY_MAIL = 27,
    EXTERNAL_GUEST_NEWSLETTER_SUBSCRIBER = 28,
    CUSTOM_TAGS = 29,
}
