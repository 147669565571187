import axios, { AxiosError, AxiosResponse } from 'axios';

export default class BookingsServices {
    async getBookings(dateStart: string, dateEnd: string) {
        return axios
            .get(`/economic-statistics/booked/${dateStart}/${dateEnd}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
    async getDashboardBookedIndividual() {
        return axios
            .get(`/economic-statistics/booked/dashboard/individual`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
