import PostSurveyServices from '@/service/survey-forms/post-survey.services';
import { defineStore, StoreDefinition } from 'pinia';

export type PostSurveyStoreState = {
    postSurveyServices: PostSurveyServices;
};

export const usePostSurveyStore: StoreDefinition = defineStore({
    id: 'mv_postSurvey',
    state: (): PostSurveyStoreState => ({
        postSurveyServices: new PostSurveyServices(),
    }),
    actions: {
        async getSurveyInfo(surveyId: string, hmac: string, establishmentId: string) {
            return await this.postSurveyServices.getSurveyInfo(surveyId, hmac, establishmentId);
        },
        async postSurvey(surveyId: string, hmac: string, kpis: Array<any>) {
            return await this.postSurveyServices.postSurvey(surveyId, hmac, kpis);
        },
    },
});
