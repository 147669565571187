import { defineStore, StoreDefinition } from 'pinia';
import BookingStatsServices from '@/service/guest-happiness/booking-stats.services';

export type BookingStatsStoreState = {
    bookingStatsService: BookingStatsServices;
};

export const useBookingStatsStore: StoreDefinition = defineStore({
    id: 'mv_bookingStats',
    state: (): BookingStatsStoreState => ({
        bookingStatsService: new BookingStatsServices(),
    }),
    actions: {
        async getBookingGradesSummaryByDates(date: Date) {
            return await this.bookingStatsService.getBookingGradesSummaryBetweenDates(date);
        },
        async getBookingReviewsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingReviewsBetweenDates(from, to);
        },
        async getBookingAmountReviewsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsBetweenDates(from, to);
        },
        async getBookingAmountPositiveReviewsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountPositiveReviewsBetweenDates(from, to);
        },
        async getBookingAmountNegativeReviewsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountNegativeReviewsBetweenDates(from, to);
        },
        async getBookingAmountDirectorAnsweredReviewsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountDirectorAnsweredReviewsBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByLanguageBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByLanguageBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByMarkBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByMarkBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByNightsBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByNightsBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByRoomTypeBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByRoomTypeBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByTravellerTypeBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByTravellerTypeBetweenDates(from, to);
        },
        async getBookingAmountReviewsGroupedByCountryBetweenDates(from: Date, to: Date) {
            return await this.bookingStatsService.getBookingAmountReviewsGroupedByCountryBetweenDates(from, to);
        },
    },
});
