import { defineStore, StoreDefinition } from 'pinia';
import MailTemplatesServices, { mailTemplate } from '@/service/mail-templates/mail-templates.services';
import { LanguageEnum, StateEnum } from '@/core/enums';
import MailTemplatesServicesV2 from '@/service/mail-templates/mail-templates-v2.services';
import { MailTemplateTypeEnum } from '@/core/enums/mail-template-type.enum';
import { IDuplicateMailTemplate } from '@/core/interfaces/mail-template/duplicate-mail-template.interface';
import { IUpdateMailTemplate } from '@/core/interfaces/mail-template/update-mail-template.interface';
import { ICreateMailTemplate } from '@/core/interfaces/mail-template/create-mail-template.interface';

export type MailTemplatesStoreState = {
    mailTemplatesService: MailTemplatesServices;
    mailTemplatesServiceV2: MailTemplatesServicesV2;
};

export const useMailTemplateStore: StoreDefinition = defineStore({
    id: 'mv_mailTemplate',
    state: (): MailTemplatesStoreState => ({
        mailTemplatesService: new MailTemplatesServices(),
        mailTemplatesServiceV2: new MailTemplatesServicesV2(),
    }),
    actions: {
        async getMailTemplates(page: number, resultsPerPage: number) {
            return await this.mailTemplatesService.getMailTemplates(page, resultsPerPage);
        },
        async addMailTemplate(name: string, language: LanguageEnum, html: string) {
            return await this.mailTemplatesService.addMailTemplate(name, language, html);
        },
        async updateMailTemplate(mail: mailTemplate) {
            return await this.mailTemplatesService.updateMailTemplate(mail);
        },
        async deleteMailTemplate(id: string) {
            return await this.mailTemplatesService.deleteMailTemplate(id);
        },

        /**** V2 ****/

        async getMailTemplate(mailTemplateId: string) {
            return await this.mailTemplatesServiceV2.getMailTemplate(mailTemplateId);
        },
        async getMailTemplatesFilteredAndPaginatedV2(
            page: number,
            resultsPerPage: number,
            languages?: LanguageEnum[],
            states?: StateEnum[],
            types?: MailTemplateTypeEnum[],
            name?: string,
        ) {
            return await this.mailTemplatesServiceV2.getMailTemplatesFilteredAndPaginated(
                page,
                resultsPerPage,
                languages,
                states,
                types,
                name,
            );
        },
        async getPublicHtml(companyId: string, establishmentId: string, mailTemplateId: string, signature: string) {
            return await this.mailTemplatesServiceV2.getPublicHtml(
                companyId,
                establishmentId,
                mailTemplateId,
                signature,
            );
        },
        async createPublicShare(mailTemplateId: string) {
            return await this.mailTemplatesServiceV2.createPublicShare(mailTemplateId);
        },
        async createMailTemplate(createMailTemplateObject: ICreateMailTemplate) {
            return await this.mailTemplatesServiceV2.createMailTemplate(createMailTemplateObject);
        },
        async updateMailTemplateV2(mailTemplateId: string, updateMailTemplateObject: IUpdateMailTemplate) {
            return await this.mailTemplatesServiceV2.updateMailTemplate(mailTemplateId, updateMailTemplateObject);
        },
        async duplicateMailTemplate(mailTemplateId: string, duplicateMailTemplateObject: IDuplicateMailTemplate) {
            return await this.mailTemplatesServiceV2.duplicateMailTemplate(mailTemplateId, duplicateMailTemplateObject);
        },
        async deleteMailTemplateV2(mailTemplateId: string) {
            return await this.mailTemplatesServiceV2.deleteMailTemplate(mailTemplateId);
        },
    },
});
