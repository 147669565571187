export enum FieldTypesEnum {
    INPUT_TEXT = 0,
    INPUT_NUMBER = 1,
    INPUT_SWITCH = 2,
    TEXTAREA = 3,
    DROPDOWN = 4,
    MULTISELECT = 5,
    RADIOBUTTON = 6,
    RATING = 7,
    SLIDER = 8,
}
