import { defineStore } from 'pinia';
import MailEditorServices from '@/service/mail-editor/mail-editor.services';

export const useMailEditorStore = defineStore({
    id: 'mv_mailEditor',
    state: () => ({
        mailEditorServices: new MailEditorServices(),
    }),
    actions: {
        async generateAuthToken() {
            return await this.mailEditorServices.generateAuthToken();
        },
    },
});
