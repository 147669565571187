import { defineStore } from 'pinia';
import UsersServices from '@/service/users/users.services';

export type UsersStoreState = {
    usersService: UsersServices;
};

export const useUsersStore = defineStore({
    id: 'mv_users',
    state: (): UsersStoreState => ({
        usersService: new UsersServices(),
    }),
    actions: {
        async sendUserFeedback(data: any, recaptchaToken: string) {
            return await this.usersService.sendUserFeedback(data, recaptchaToken);
        },
    },
});
