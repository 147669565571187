import axios, { AxiosError, AxiosResponse } from 'axios';

export default class UsersServices {
    async sendUserFeedback(data: any, recaptchaToken: string) {
        return axios
            .post(`/users/feedback`, data, {
                headers: {
                    'X-Metriguest-Captcha': recaptchaToken,
                },
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
