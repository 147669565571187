import { defineStore } from 'pinia';
import KpisServices from '@/service/kpis/kpis.services';

export const useKpisStore = defineStore({
    id: 'mv_kpis',
    state: () => ({
        kpisServices: new KpisServices(),
    }),
    actions: {
        async getKpiById(id: string) {
            return await this.kpisServices.getKpiById(id);
        },
        async getAllKpisPaginated(page: number, resultsByPage: number) {
            return await this.kpisServices.getAllKpisPaginated(page, resultsByPage);
        },
        async createKpi(kpi: any) {
            return await this.kpisServices.createKpi(kpi);
        },
        async updateKpi(id: string, kpi: any) {
            return await this.kpisServices.updateKpi(id, kpi);
        },
        async deleteKpi(id: string) {
            return await this.kpisServices.deleteKpi(id);
        },
    },
});
