<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PreviewMailEditor',
    props: {
        html: {
            required: true,
            default: null,
            type: String,
        },
    },
    methods: {
        generateBlob() {
            return new Blob([this.html], { type: 'text/html' });
        },
        renderPreview() {
            const _blob = this.generateBlob();

            const _containerDesktopPreview = document.getElementById('containerDesktopPreview') as HTMLElement;
            if (_containerDesktopPreview?.firstChild) _containerDesktopPreview.firstChild.remove();
            const iframe_desktop = document.createElement('iframe');
            iframe_desktop.src = window.URL.createObjectURL(_blob);
            iframe_desktop.style.height = '100%';
            iframe_desktop.style.border = 'unset';
            _containerDesktopPreview.appendChild(iframe_desktop);

            const _containerMobilePreview = document.getElementById('containerMobilePreview') as HTMLElement;
            if (_containerMobilePreview?.firstChild) _containerMobilePreview.firstChild.remove();
            const iframe_mobile = document.createElement('iframe');
            iframe_mobile.src = window.URL.createObjectURL(_blob);
            iframe_mobile.width = '320';
            iframe_mobile.height = '550';
            iframe_mobile.style.border = 'unset';
            _containerMobilePreview.appendChild(iframe_mobile);
        },
    },
    watch: {
        $props: {
            handler() {
                this.renderPreview();
            },
            deep: true,
        },
    },
    mounted() {
        this.renderPreview();
    },
});
</script>

<template>
    <div class="flex flex-row justify-content-around w-full">
        <div
            class="show-pc flex-column border-round-3xl bg-white p-3"
            style="width: calc(100vw - 520px); height: calc(100vh - 100px)"
            id="containerDesktopPreview"
        ></div>
        <div class="show-phone flex-column border-round-3xl bg-white p-3" style="max-height: 680px">
            <img src="/layout/images/email-editor/device-mobile-top-bar.png" width="320" />
            <div id="containerMobilePreview"></div>
            <img src="/layout/images/email-editor/device-mobile-bottom-bar.png" width="320" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.show-pc,
.show-phone {
    display: none;
}

@media only screen and (max-width: 765px) {
    .show-phone {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .show-pc {
        display: flex !important;
    }
}

@media only screen and (min-width: 1024px) {
    .show-phone,
    .show-pc {
        display: flex !important;
    }
}
</style>
