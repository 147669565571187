import axios, { AxiosError, AxiosResponse } from 'axios';
import { ICreateMailCampaign } from '@/core/interfaces/mail-campaign/create-mail-campaign.interface';

export default class MailCampaignServices {
    async getMailCampaigns(page: number, resultsPerPage: number) {
        return axios
            .get(`/mail-campaign/${page}/${resultsPerPage}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getMailCampaign(mailCampaignId: string) {
        return axios
            .get(`/mail-campaign/${mailCampaignId}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    //Mail templates
    async getMailTemplatesFiltered(page: number, resultsByPage: number, language: Array<number>, state: Array<number>) {
        return axios
            .post(`/mail-template/filtered/`, {
                page: page,
                resultsByPage: resultsByPage,
                language: language,
                state: state,
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async createMailCampaign(createMailCampaignObject: ICreateMailCampaign): Promise<AxiosResponse<any>> {
        return await axios.post('/mail-campaign/', createMailCampaignObject);
    }
}
