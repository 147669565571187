import axios, { AxiosError, AxiosResponse } from 'axios';

export default class MailCampaignStatsServices {
    async getGeneralMailgunStatsByTag(mailCampaign: string, mainTag: string, langTag: string, lang: string) {
        return axios
            .get(`/mailgun-stats/general/${mailCampaign}/${mainTag}/${langTag}/${lang}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getDeviceMailgunStatsByTag(mailCampaign: string, tag: string) {
        return axios
            .get(`/mailgun-stats/devices/${mailCampaign}/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getProviderMailgunStatsByTag(mailCampaign: string, tag: string) {
        return axios
            .get(`/mailgun-stats/providers/${mailCampaign}/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getCountriesMailgunStatsByTag(mailCampaign: string, tag: string) {
        return axios
            .get(`/mailgun-stats/countries/${mailCampaign}/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getByDayGroupedMailgunStats(mailCampaign: string, tag: string) {
        return axios
            .get(`/mailgun-stats/days/${mailCampaign}/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getByHourGroupedMailgunStats(mailCampaign: string, tag: string) {
        return axios
            .get(`/mailgun-stats/hours/${mailCampaign}/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getMostClickedLinks(tag: string) {
        return axios
            .get(`/mailgun-stats/clicks/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getMostClickedLinks_V2(tag: string) {
        return axios
            .get(`/v2/mailgun-stats/clicks/${tag}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
