<template>
    <div
        :class="['layout-inline-menu', { 'layout-inline-menu-active': activeInlineProfile }]"
        v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())"
    >
        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
            <img :src="this.getPictureProfile" alt="avatar" style="width: 44px; height: 44px" />
            <span class="layout-inline-menu-text">{{ this.getUserName }}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item">
                    <a @click="handleLogOut">
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Logout</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item hidden">
                    <a>
                        <i class="pi pi-cog pi-fw"></i>
                        <span>Settings</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item hidden">
                    <a>
                        <i class="pi pi-user pi-fw"></i>
                        <span>Profile xxxx appInlinemenu</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a class="a-day-night">
                        <i class="pi pi-sun pi-fw"></i>
                        <InputSwitch v-model="isDarkMode" @click="changeLayoutColourMode" />
                        <i class="pi pi-moon pi-fw icon-night"></i>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a @click.prevent="onSendCommentsClick">
                        <i class="pi pi-question-circle pi-fw"></i>
                        <span>Enviar comentarios</span>
                    </a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConfigurationStore, useAuthStore } from '@/store';

export default defineComponent({
    name: 'AppInlineMenu',
    emits: ['change-inlinemenu', 'change-dark-mode', 'send-comments-click'],
    props: {
        menuMode: String,
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
    },
    setup: () => {
        const configurationStore = useConfigurationStore();
        const authStore = useAuthStore();
        return { configurationStore, authStore };
    },
    data: () => ({
        isDarkMode: false,
    }),
    methods: {
        onChangeActiveInlineMenu(event: any) {
            this.$emit('change-inlinemenu', event);
            event.preventDefault();
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        changeLayoutColourMode() {
            this.isDarkMode = !this.isDarkMode;
            this.configurationStore.changeDarkMode(this.isDarkMode);
            if (this.isDarkMode) {
                this.$emit('change-dark-mode', 'teal', 'dark');
            } else {
                this.$emit('change-dark-mode', 'blue', 'light');
            }
        },
        handleLogOut() {
            this.authStore.logOut();
            this.$router.push({ name: 'login' });
        },
        onSendCommentsClick() {
            this.$emit('send-comments-click');
        },
    },
    computed: {
        getPictureProfile: function () {
            return this.authStore.getUserPictureProfile;
        },
        getUserName: function () {
            return this.authStore.getUserFullName;
        },
    },
    mounted() {
        this.isDarkMode = this.configurationStore.isDarkMode;
    },
});
</script>

<style scoped>
.icon-night {
    margin-left: 0.75rem;
}
.a-day-night {
    background-color: unset !important;
}
@media (min-width: 992px) {
    .layout-wrapper.layout-sidebar
        .layout-menu-wrapper.layout-sidebar-active
        .layout-inline-menu
        .layout-inline-menu-action-panel
        .layout-inline-menu-action-item
        a
        i.icon-night,
    .layout-wrapper.layout-sidebar
        .layout-menu-wrapper.layout-sidebar-active
        .layout-inline-menu
        .layout-inline-menu-action-panel
        .layout-inline-menu-action-item
        a
        div.p-inputswitch {
        visibility: visible;
    }

    .layout-wrapper.layout-sidebar
        .layout-menu-wrapper
        .layout-inline-menu
        .layout-inline-menu-action-panel
        .layout-inline-menu-action-item
        a
        i.icon-night,
    .layout-wrapper.layout-sidebar
        .layout-menu-wrapper
        .layout-inline-menu
        .layout-inline-menu-action-panel
        .layout-inline-menu-action-item
        a
        div.p-inputswitch {
        white-space: nowrap;
        visibility: hidden;
    }
}
</style>
