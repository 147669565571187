import axios, { AxiosError, AxiosResponse } from 'axios';

export default class BusinessOnTheBooksServices {
    async getBusinessOnTheBooks(dateStart: string, dateEnd: string) {
        return axios
            .get(`/economic-statistics/on-site/${dateStart}/${dateEnd}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getDashboardEconomicIndividual() {
        return axios
            .get(`/economic-statistics/on-site/dashboard/individual`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getDashboardIdsIndividual() {
        return axios
            .get(`/ids/dashboard/individual`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
