import { defineStore, StoreDefinition } from 'pinia';
import SurveyEditorServices from '@/service/guest-happiness/survey-editor.services';
import { ISurveyForm } from '@/components/guest-happiness/email-editor/SurveyEditor.interface';

export type SurveyEditorStoreState = {
    surveyEditorService: SurveyEditorServices;
};

export const useSurveyEditorStore: StoreDefinition = defineStore({
    id: 'mv_surveyEditor',
    state: (): SurveyEditorStoreState => ({
        surveyEditorService: new SurveyEditorServices(),
    }),
    actions: {
        async getSurveyForms(page: number, resultsPerPage: number) {
            return await this.surveyEditorService.getSurveyForms(page, resultsPerPage);
        },
        async getSurveyForm(surveyFormId: string) {
            return await this.surveyEditorService.getSurveyForm(surveyFormId);
        },
        async getSurveyFormFiltered() {
            return await this.surveyEditorService.getSurveyFormFiltered();
        },
        async addSurveyForm(surveyForm: ISurveyForm) {
            return await this.surveyEditorService.addSurveyForm(surveyForm);
        },
        async updateSurveyForm(surveyForm: ISurveyForm) {
            return await this.surveyEditorService.updateSurveyForm(surveyForm);
        },
        async deleteSurveyForm(id: string) {
            return await this.surveyEditorService.deleteSurveyForm(id);
        },
    },
});
