import { defineStore } from 'pinia';
import CustomTagsServices from '@/service/custom-tags/custom-tags.services';
import { StateEnum } from '@/core/enums';

export const useCustomTagsStore = defineStore({
    id: 'mv_custom_tags',
    state: () => ({
        customTagsServices: new CustomTagsServices(),
    }),
    actions: {
        async getCustomTag(customTagId: string) {
            return await this.customTagsServices.getCustomTag(customTagId);
        },
        async getCustomTagsFilteredPaginated(
            page: number,
            resultsPerPage: number,
            establishments?: string[],
            states?: StateEnum[],
            name?: string,
        ) {
            return await this.customTagsServices.getCustomTagsFilteredPaginated(
                page,
                resultsPerPage,
                establishments,
                states,
                name,
            );
        },
        async createCustomTag(name: string) {
            return await this.customTagsServices.createCustomTag(name);
        },
        async patchCustomTag(customTagId: string, name: string) {
            return await this.customTagsServices.patchCustomTag(customTagId, name);
        },
        async deleteCustomTag(customTagId: string) {
            return await this.customTagsServices.deleteCustomTag(customTagId);
        },
    },
});
