import BusinessOnTheBooksServices from '@/service/business-on-the-books/business-on-the-books.services';
import { defineStore, StoreDefinition } from 'pinia';

export type BusinessOnTheBooksStoreState = {
    businessOnTheBooksService: BusinessOnTheBooksServices;
};

export const useBusinessOnTheBooksStore: StoreDefinition = defineStore({
    id: 'mv_businessOnTheBooks',
    state: (): BusinessOnTheBooksStoreState => ({
        businessOnTheBooksService: new BusinessOnTheBooksServices(),
    }),
    actions: {
        async getBusinessOnTheBooks(dateStart: string, dateEnd: string) {
            return await this.businessOnTheBooksService.getBusinessOnTheBooks(dateStart, dateEnd);
        },
        async getDashboardEconomicIndividual() {
            return await this.businessOnTheBooksService.getDashboardEconomicIndividual();
        },
        async getDashboardIdsIndividual() {
            return await this.businessOnTheBooksService.getDashboardIdsIndividual();
        },
    },
});
