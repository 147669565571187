import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useAuthStore, useEstablishmentStore } from '@/store';
import Constants from '@/core/constants';

interface ICInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry: boolean;
    _cc: boolean;
}

interface ICAxiosResponse extends AxiosResponse {
    config: ICInternalAxiosRequestConfig;
}

export default function axiosSetup() {
    const authStore = useAuthStore();
    const establishmentStore = useEstablishmentStore();

    axios.defaults.baseURL = Constants.API_BASE_URL;
    axios.defaults.timeout = Constants.AXIOS_MAX_TIMEOUT;
    axios.defaults.headers.common['X-CRACK-ME'] = 'a2VlcCBsb29raW5nIGFuZCB5b3Ugd2lsbCBmaW5kIHRoZSBlYXN0ZXIgZWdn';

    axios.defaults.validateStatus = () => true;
    axios.defaults.paramsSerializer = {
        indexes: null,
    };

    axios.interceptors.request.use(
        function (config: InternalAxiosRequestConfig) {
            const token = authStore.getUserToken;
            if (config.headers === undefined) {
                config.headers = {} as AxiosRequestHeaders;
            }
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    axios.interceptors.request.use(
        function (config: InternalAxiosRequestConfig) {
            const establishment = establishmentStore.getEstablishmentSelected;
            if (establishment) {
                if (config.headers === undefined) {
                    config.headers = {} as AxiosRequestHeaders;
                }
                config.headers[Constants.METRIGUEST.DEFAULT_ESTABLISHMENT_HEADER] = establishment;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        // @ts-expect-error just to bypass lint error
        async (response: ICAxiosResponse) => {
            if (response.status === 401 && response.config.url?.includes('auth/refresh')) {
                authStore.logOut();
            } else if (response.status === 401 && response.config.url?.includes('auth/login')) {
                return response;
            } else if (response.status === 401 && !response.config?._retry) {
                response.config._retry = true;
                response.config._cc = true;
                await authStore.refreshToken();
                const token = authStore.getUserToken;
                response.config.headers.Authorization = `Bearer ${token}`;
                return axios(response.config);
            } else {
                return response;
            }
        },
        (error) => {
            return Promise.reject(error);
        },
    );
}
