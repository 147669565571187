export enum MailTemplateTypeEnum {
    PreStay = 0,
    PostStay = 1,
    Marketing = 2,
    Newsletter = 3,
    Legal = 4,
    DirectorAnswer = 5,
    MorningStatsSummarySingle = 6,
    MorningStatsSummaryMultiple = 7,
}
