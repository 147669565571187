import axios, { AxiosResponse } from 'axios';
import { IPagination } from '@/core/interfaces/pagination.interface';
import { IMailTemplate } from '@/core/interfaces/mail-template/mail-template.interface';
import { LanguageEnum, StateEnum } from '@/core/enums';
import { MailTemplateTypeEnum } from '@/core/enums/mail-template-type.enum';
import { IDuplicateMailTemplate } from '@/core/interfaces/mail-template/duplicate-mail-template.interface';
import { IUpdateMailTemplate } from '@/core/interfaces/mail-template/update-mail-template.interface';
import { ICreateMailTemplate } from '@/core/interfaces/mail-template/create-mail-template.interface';

export default class MailTemplatesServicesV2 {
    async getMailTemplate(mailTemplateId: string): Promise<AxiosResponse<IMailTemplate>> {
        return await axios.get(`/mail-template/${mailTemplateId}`);
    }

    async getMailTemplatesFilteredAndPaginated(
        page: number,
        resultsPerPage: number,
        languages?: LanguageEnum[],
        states?: StateEnum[],
        types?: MailTemplateTypeEnum[],
        name?: string,
    ): Promise<AxiosResponse<IPagination<IMailTemplate>>> {
        return await axios.get(`/v2/mail-template/filtered`, {
            params: {
                page,
                resultsPerPage,
                ...(languages && Array.isArray(languages) && languages.length && { languages: languages }),
                ...(states && Array.isArray(states) && states.length && { states: states }),
                ...(types && Array.isArray(types) && types.length && { types: types }),
                ...(name && name?.length && { name: name }),
            },
        });
    }

    async getPublicHtml(
        companyId: string,
        establishmentId: string,
        mailTemplateId: string,
        signature: string,
    ): Promise<AxiosResponse<{ html: string }>> {
        return await axios.get(`/mail-template/public/html/${mailTemplateId}/`, {
            params: {
                company: companyId,
                establishment: establishmentId,
                signature,
            },
        });
    }

    async createPublicShare(mailTemplateId: string): Promise<
        AxiosResponse<{
            url: string;
            timestamp: string;
            company: string;
            establishment: string;
            signature: string;
        }>
    > {
        return await axios.post(`/mail-template/public/share/${mailTemplateId}`);
    }

    async createMailTemplate(createMailTemplateObject: ICreateMailTemplate): Promise<AxiosResponse<IMailTemplate>> {
        return axios.post(`/mail-template`, createMailTemplateObject);
    }

    async updateMailTemplate(
        mailTemplateId: string,
        updateMailTemplateObject: IUpdateMailTemplate,
    ): Promise<AxiosResponse<IMailTemplate>> {
        return axios.patch(`/v2/mail-template/${mailTemplateId}`, updateMailTemplateObject);
    }

    async duplicateMailTemplate(
        mailTemplateId: string,
        duplicateMailTemplateObject: IDuplicateMailTemplate,
    ): Promise<AxiosResponse<IMailTemplate>> {
        return axios.post(`/mail-template/duplicate/${mailTemplateId}`, duplicateMailTemplateObject);
    }

    async deleteMailTemplate(mailTemplateId: string): Promise<AxiosResponse<IMailTemplate>> {
        return axios.delete(`/v2/mail-template/${mailTemplateId}`);
    }
}
