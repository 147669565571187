import { defineStore } from 'pinia';

type IEstablishment = {
    establishment: {
        _id: string;
        name: string;
        logo: string;
        role: {
            code: number;
        };
    };
};

export type EstablishmentStoreState = {
    establishmentSelected: string | null;
    establishments: Array<IEstablishment>;
};

export const useEstablishmentStore = defineStore({
    id: 'mv_establishment',
    state: () =>
        ({
            establishmentSelected: null,
            establishments: [],
        }) as EstablishmentStoreState,
    getters: {
        getEstablishmentSelected: (state: EstablishmentStoreState) => state.establishmentSelected,
        getEstablishmentsToShow: (state: EstablishmentStoreState) =>
            state.establishments.map((e) => {
                return {
                    _id: e.establishment._id,
                    name: e.establishment.name,
                    logo: e.establishment.logo,
                    active: e.establishment._id === state.establishmentSelected,
                };
            }),
    },
    actions: {
        setEstablishmentSelected(establishmentId: string) {
            this.establishmentSelected = establishmentId;
        },
        setEstablishments(establishments: Array<IEstablishment>) {
            this.establishments = establishments;
        },
    },
    persist: {
        storage: window.sessionStorage,
    },
});
