import { createRouter, createWebHashHistory } from 'vue-router';
import { useAuthStore, useEstablishmentStore } from '@/store';
import { RoleEnum, SubModuleEnum } from '@/core/enums';

const routes = [
    {
        path: '/',
        name: 'dashboardd',
        meta: {
            isAuthenticated: true,
        },
        component: () => import('@/components/HelloWorld.vue'),
    },
    {
        path: '/dashboard-single',
        name: 'dashboard-single',
        component: () => import('@/components/dashboard/DashboardSingle.vue'),
        meta: {
            isAuthenticated: true,
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard Single' }],
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/dashboard-multiple',
        name: 'dashboard-multiple',
        component: () => import('@/components/dashboard/DashboardMultiple.vue'),
        meta: {
            isAuthenticated: true,
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard Multiple' }],
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
            static: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login.vue'),
    },
    {
        path: '/reset',
        name: 'reset',
        component: () => import('@/pages/ResetPassword.vue'),
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import('@/pages/ForgotPassword.vue'),
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: () => import('@/pages/NotFound.vue'),
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: () => import('@/pages/AccessDenied.vue'),
    },
    {
        path: '/booking-dashboard',
        name: 'booking-dashboard',
        component: () => import('@/components/guest-happiness/booking/BookingDashboard.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Booking Comment Dashboard' }],
            subModules: [SubModuleEnum.BOOKING_DASHBOARD],
        },
    },
    {
        path: '/booking-timeline',
        name: 'booking-timeline',
        component: () => import('@/components/guest-happiness/booking/BookingTimeline.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Booking Comment Timeline' }],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/google-dashboard',
        name: 'google-dashboard',
        component: () => import('@/components/guest-happiness/google/google-dashboard/GoogleDashboard.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Google Comment Dashboard' }],
            subModules: [SubModuleEnum.GOOGLE_DASHBOARD],
        },
    },
    {
        path: '/google-timeline',
        name: 'google-timeline',
        component: () => import('@/components/guest-happiness/google/GoogleTimeline.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Google Comment Timeline' }],
            subModules: [SubModuleEnum.GOOGLE_COMMENT_TIMELINE],
        },
    },
    {
        path: '/business-on-the-books',
        name: 'business-on-the-books',
        component: () => import('@/components/performance/business-on-the-books/BusinessOnTheBooks.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Performance', label: 'Business on the Books' }],
            subModules: [SubModuleEnum.BUSINESS_ON_THE_BOOKS],
        },
    },
    {
        path: '/on-site-by-dates',
        name: 'on-site-by-dates',
        component: () => import('@/components/performance/on-site-by-dates/OnSiteByDates.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Performance', label: 'OnSite by Dates' }],
            subModules: [SubModuleEnum.BUSINESS_ON_THE_BOOKS],
        },
    },
    {
        path: '/bookings',
        name: 'bookings',
        component: () => import('@/components/performance/bookings/Bookings.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Performance', label: 'Bookings' }],
            subModules: [SubModuleEnum.BOOKINGS],
        },
    },
    {
        path: '/airbnb-timeline',
        name: 'airbnb-timeline',
        component: () => import('@/components/guest-happiness/airbnb/AirbnbTimeline.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Airbnb Comment Timeline' }],
            subModules: [SubModuleEnum.AIRBNB_COMMENT_TIMELINE],
        },
    },
    {
        path: '/airbnb-dashboard',
        name: 'airbnb-dashboard',
        component: () => import('@/components/guest-happiness/airbnb/AirbnbDashboard.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Airbnb Comment Dashboard' }],
            subModules: [SubModuleEnum.AIRBNB_DASHBOARD],
        },
    },
    {
        path: '/survey-editor',
        name: 'survey-editor',
        component: () => import('@/components/guest-happiness/email-editor/SurveyEditor.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/survey-forms',
        name: 'survey-forms',
        component: () => import('@/components/guest-happiness/survey-forms/SurveyForms.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.SURVEY_EDITOR],
        },
    },
    {
        path: '/survey-form-editor/:id?',
        name: 'survey-form-editor',
        component: () => import('@/components/guest-happiness/survey-forms/SurveyFormEditor.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.SURVEY_EDITOR],
        },
    },
    {
        path: '/survey-editor2',
        name: 'survey-editor2',
        component: () => import('@/components/guest-happiness/survey-forms/SurveyFormEditor.vue'),
        meta: {
            //roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            //subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE]
        },
    },
    {
        path: '/rate-shopper',
        name: 'rate-shopper',
        component: () => import('@/components/rate-shopper/rate-shopper/RateShopper.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/metriguest-dashboard',
        name: 'metriguest-dashboard',
        component: () => import('@/components/guest-happiness/metriguest/MetriguestDashboard.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Metriguest Comment Dashboard' }],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/metriguest-timeline',
        name: 'metriguest-timeline',
        component: () => import('@/components/guest-happiness/metriguest/MetriguestTimeline.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Metriguest Comment Timeline' }],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/metriguest-timeline-newest-responses',
        name: 'metriguest-timeline-newest-responses',
        component: () => import('@/components/guest-happiness/metriguest/MetriguestTimelineNewestResponses.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            breadcrumb: [{ parent: 'Guest Happiness', label: 'Metriguest Comment Timeline' }],
            subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
        },
    },
    {
        path: '/budgets',
        name: 'budgets',
        component: () => import('@/components/performance/budget/Budgets.vue'),
        meta: {
            isAuthenticated: true,
        },
    },
    {
        path: '/budget/:id?',
        name: 'budget',
        component: () => import('@/components/performance/budget/Budget.vue'),
        meta: {
            isAuthenticated: true,
        },
    },
    {
        path: '/budget-form',
        name: 'budget-form',
        component: () => import('@/components/performance/budget/BudgetForm.vue'),
        meta: {
            isAuthenticated: true,
        },
    },
    {
        path: '/mail-templates',
        name: 'mail-templates',
        component: () => import('@/components/smart-mailing/mail-templates/MailTemplates.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor],
            breadcrumb: [{ parent: 'SmartMailing', label: 'Mail Templates' }],
            subModules: [SubModuleEnum.TEMPLATES],
        },
    },
    {
        path: '/mail-visual-editor/:id?',
        name: 'mail-visual-editor',
        component: () => import('@/components/smart-mailing/mail-visual-editor/MailVisualEditor.vue'),
        meta: {
            static: true,
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor],
            breadcrumb: [{ parent: 'SmartMailing', label: 'Mail Visual Editor' }],
            subModules: [SubModuleEnum.EMAIL_EDITOR, SubModuleEnum.EMAIL_BUILDER],
        },
    },
    {
        path: '/mail-templates/public/preview/:id?',
        name: 'mail-template-public-preview',
        component: () => import('@/components/smart-mailing/mail-templates/MailTemplatePublicPreview.vue'),
    },
    {
        path: '/mail-campaigns',
        name: 'mail-campaigns',
        component: () => import('@/components/smart-mailing/mail-campaigns/MailCampaigns.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor],
            breadcrumb: [{ parent: 'SmartMailing', label: 'Campaign Reports' }],
            subModules: [SubModuleEnum.CAMPAIGN_REPORTS],
        },
    },
    {
        path: '/mail-campaign-stats/:id?',
        name: 'mail-campaign-stats',
        component: () => import('@/components/smart-mailing/mail-campaigns/MailCampaignStats.vue'),
        meta: {
            isAuthenticated: true,
            roles: [RoleEnum.Editor, RoleEnum.Company_Editor],
            breadcrumb: [{ parent: 'SmartMailing', label: 'Campaign Stats' }],
            subModules: [SubModuleEnum.CAMPAIGN_REPORTS],
        },
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/not-found',
    },
    // {
    //     path: '/post-survey-forms',
    //     name: 'post-survey',
    //     component: () => import('@/pages/PostSurvey.vue'),
    // },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const establishmentStore = useEstablishmentStore();

    const authenticated = authStore.isAuthenticated;
    const establishment = establishmentStore.getEstablishmentSelected;
    const roles = authStore.getRolesByEstablishment(establishment);
    const subModules = authStore.getSubModulesByEstablishment(establishment);

    if (to.meta?.isAuthenticated && !authenticated) {
        next({ name: 'login' });
    } else if (to?.meta?.roles && !to.meta.roles.some((role) => roles?.includes(role))) {
        next({ name: 'access-denied' });
    } else if (to?.meta?.subModules && !to.meta.subModules.some((subModule) => subModules?.includes(subModule))) {
        next({ name: 'access-denied' });
    } else {
        next();
    }
});

export default router;
