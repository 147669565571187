<template>
    <div class="layout-breadcrumb-container">
        <div class="layout-breadcrumb-left-items">
            <a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
                <i class="pi pi-bars"></i>
            </a>

            <Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb>
        </div>
        <div class="layout-breadcrumb-right-items">
            <!-- <a tabindex="0" class="search-icon" @click="breadcrumbClick">
                <i class="pi pi-search"></i>
            </a>

            <div class="search-wrapper" :class="{ 'active-search-wrapper': searchActive }">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <InputText placeholder="Search..." v-model="search" @click="inputClick" />
                </span>
            </div> -->

            <div id="layout-config">
                <span class="" data-v-step="1">
                    <template v-for="_establishment in userEstablishments" :key="_establishment._id">
                        <template v-if="_establishment.active">
                            <Button
                                :label="_establishment.name"
                                icon="pi pi-building"
                                class="layout-rightmenu-button"
                                @click="onConfigButtonClick($event)"
                            ></Button>
                        </template>
                    </template>

                    <div class="layout-config" :class="{ 'layout-config-active': configActive }" @click="onConfigClick">
                        <div class="grid">
                            <div class="col-12" v-for="_establishment in userEstablishments" :key="_establishment._id">
                                <Button
                                    type="button"
                                    class="p-button-raised w-full btn-establishment flex-column"
                                    :class="{
                                        'p-button-secondary': !_establishment.active,
                                        'p-button-success': _establishment.active,
                                    }"
                                    @click.prevent="onHotelSelected(_establishment._id)"
                                >
                                    <div>
                                        <img alt="logo" :src="_establishment.logo" class="bg-white w-full" />
                                    </div>
                                    <div class="ml-auto mr-auto font-bold">{{ _establishment.name }}</div>
                                </Button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </span>
            </div>

            <span class="hidden">
                <Button
                    icon="pi pi-bookmark"
                    class="layout-rightmenu-button"
                    @click="onConfigButtonClick($event)"
                ></Button>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useEstablishmentStore, useConfigurationStore } from '@/store';

export default defineComponent({
    props: {
        menuMode: String,
        searchActive: {
            type: Boolean,
            default: false,
        },
        searchClick: {
            type: Boolean,
            default: false,
        },
        configActive: {
            type: Boolean,
            default: null,
        },
        configClick: {
            type: Boolean,
            default: null,
        },
    },
    emits: [
        'menubutton-click',
        'rightmenu-button-click',
        'update:searchActive',
        'update:searchClick',
        'config-button-click',
        'config-click',
    ],
    setup() {
        const establishmentStore = useEstablishmentStore();
        const configurationStore = useConfigurationStore();
        return { establishmentStore, configurationStore };
    },
    data() {
        return {
            userEstablishments: [] as any,
            items: [] as any,
            search: '',
        };
    },
    computed: {
        iconColor() {
            return this.configurationStore.isDarkMode ? '#2b2f3c' : 'white';
        },
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];

                const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
                for (const pro in bc) {
                    this.items.push({ label: bc[pro] });
                }
            }
        },
        onMenuButtonClick(event: any) {
            this.$emit('menubutton-click', event);
        },
        onRightMenuButtonClick() {
            this.$emit('rightmenu-button-click');
        },
        inputClick() {
            this.$emit('update:searchClick', true);
        },
        breadcrumbClick() {
            this.$emit('update:searchActive', true);
            this.$emit('update:searchClick', true);
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        onConfigButtonClick(event: { preventDefault: () => void }) {
            this.$emit('config-button-click', event);
            event.preventDefault();
        },
        onConfigClick(event: any) {
            this.$emit('config-click', event);
        },
        onHotelSelected(establishmentId: string) {
            this.establishmentStore.setEstablishmentSelected(establishmentId);
            this.$emit('config-button-click', event);
        },
    },
    mounted() {
        this.userEstablishments = this.establishmentStore.getEstablishmentsToShow;
        this.establishmentStore.$subscribe(() => {
            this.userEstablishments = this.establishmentStore.getEstablishmentsToShow;
        });
    },
});
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
    display: none;
}
</style>
