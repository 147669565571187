import axios, { AxiosError, AxiosResponse } from 'axios';
import { LanguageEnum } from '@/core/enums';

export type mailTemplate = {
    _id: string;
    language?: number;
    html?: string;
    name?: string;
    state?: number;
};

export default class MailTemplatesServices {
    async getMailTemplates(page: number, resultsPerPage: number) {
        return axios
            .get(`/mail-template/${page}/${resultsPerPage}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async addMailTemplate(name: string, language: LanguageEnum, html: string) {
        return axios
            .post(`/mail-template`, {
                name: name,
                language: language,
                html: html,
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async updateMailTemplate(mail: mailTemplate) {
        return axios
            .put(`/mail-template`, mail)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async deleteMailTemplate(id: string) {
        return axios
            .delete(`/mail-template/${id}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
