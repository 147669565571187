<template>
    <div class="login-body">
        <div class="login-image">
            <img src="/layout/images/pages/login-image.jpeg" alt="atlantis" />
        </div>
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'/layout/images/pages/login-logo-' + loginColor + '.png'" class="login-appname" />
                </div>
                <div class="form-container">
                    <div class="field">
                        <span class="p-input-icon-left">
                            <i class="pi pi-lock"></i>
                            <Password
                                placeholder="Password"
                                input-style="padding-left: 2.142rem;"
                                v-model="password"
                                :feedback="false"
                                toggle-mask
                                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                            />
                        </span>
                        <small
                            v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response"
                            class="p-error"
                            >Password is required!</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-input-icon-left">
                            <i class="pi pi-lock"></i>
                            <Password
                                placeholder="Password"
                                input-style="padding-left: 2.142rem;"
                                v-model="confirmPassword"
                                :feedback="false"
                                toggle-mask
                                :class="{ 'p-invalid': v$.confirmPassword.$invalid && submitted }"
                            />
                        </span>
                        <small
                            v-if="(v$.confirmPassword.$invalid && submitted) || v$.confirmPassword.$pending.$response"
                            class="p-error"
                            >Passwords do not match</small
                        >
                    </div>
                </div>
                <div class="button-container">
                    <Button
                        type="button"
                        label="Set new password"
                        class="p-button-outlined p-button-warning"
                        @click="handleLogin(!v$.$invalid)"
                    ></Button>
                </div>
            </div>

            <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <span>Made with 💖 by</span>
                    <img
                        src="https://www.undercoverlab.com/wp-content/uploads/2020/04/logo_undercoverlab.png"
                        class="login-footer-logo"
                    />
                </div>
                <span>Copyright {{ new Date().getFullYear() }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { required, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    setup: () => ({
        v$: useVuelidate(),
    }),
    data() {
        return {
            password: '',
            confirmPassword: '',
            submitted: false,
        };
    },
    validations() {
        return {
            password: {
                required,
            },
            confirmPassword: {
                sameAsPassword: sameAs(this.password),
            },
        };
    },
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'onlight';
            return 'ondark';
        },
    },
    methods: {
        handleLogin(isFormValid: boolean) {
            this.submitted = true;
            if (!isFormValid) {
                return;
            }
        },
    },
    beforeMount() {
        const route = useRoute();
        const router = useRouter();
        if (!route.query?.token) {
            router.push('/login');
        }
    },
});
</script>

<style scoped>
.login-body .login-panel .login-appname {
    width: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.login-body .login-panel {
    width: unset !important;
}

.field {
    text-align: left;
}

.login-footer .login-footer-logo-container span {
    margin-right: 0.3rem;
}

@media (max-width: 991px) {
    .login-body .login-panel {
        width: 100% !important;
    }
}
</style>
