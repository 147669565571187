import axios, { AxiosError, AxiosResponse } from 'axios';

export default class PostSurveyServices {
    async getSurveyInfo(surveyId: string, hmac: string, establishmentId: string) {
        return axios
            .get(`/survey/public/${surveyId}/${hmac}`, {
                headers: {
                    'X-Metriguest-Establishment': establishmentId,
                },
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async postSurvey(surveyId: string, hmac: string, kpis: Array<any>) {
        return axios
            .post(`/survey/public/kpis`, {
                _id: surveyId,
                hmac: hmac,
                kpis: kpis,
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
