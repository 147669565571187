import { defineStore, StoreDefinition } from 'pinia';
import RateShopperServices from '@/service/rate-shopper/rate-shopper.services';

export type RateShopperStoreState = {
    rateShopperService: RateShopperServices;
};

export const useRateShopperStore: StoreDefinition = defineStore({
    id: 'mv_rateShopper',
    state: (): RateShopperStoreState => ({
        rateShopperService: new RateShopperServices(),
    }),
    actions: {
        async getPricesBetweenDates(competitiveSet: string, from: Date, to: Date) {
            return await this.rateShopperService.getPricesBetweenDates(competitiveSet, from, to);
        },
    },
});
