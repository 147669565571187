export const TourSteps = [
    {
        target: '[data-v-step="0"]',
        header: {
            title: 'Select Establishment',
        },
        content: 'Here you can select the establishment that you want to use!',
    },
    {
        target: '[data-v-step="1"]',
        header: {
            title: 'Summary Information',
        },
        content: 'Here you can see a summary of your information and some news!',
    },
    {
        target: '[data-v-step="2"]',
        header: {
            title: 'Settings',
        },
        params: {
            position: 'right',
        },
        content: 'Here you can change your personal information and layout configuration',
    },
];
