<template>
    <div class="login-body">
        <div class="login-image">
            <img src="/layout/images/pages/login-image.jpeg" alt="atlantis" />
        </div>
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'/layout/images/pages/login-logo-' + loginColor + '.png'" class="login-appname" />
                </div>
                <div class="form-container">
                    <div class="field">
                        <span class="p-input-icon-left">
                            <i class="pi pi-envelope"></i>
                            <InputText
                                type="text"
                                placeholder="Email"
                                v-model="email"
                                :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                            />
                        </span>
                        <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error"
                            >Email is required!</small
                        >
                    </div>
                    <router-link to="/login">
                        <a class="flex">Sign In?</a>
                    </router-link>
                </div>
                <div class="button-container">
                    <Button
                        type="button"
                        label="Send recover link"
                        class="p-button-warning"
                        @click="handleLogin(!v$.$invalid)"
                    ></Button>
                </div>
            </div>

            <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <span>Made with 💖 by</span>
                    <img
                        src="https://www.undercoverlab.com/wp-content/uploads/2020/04/logo_undercoverlab.png"
                        class="login-footer-logo"
                    />
                </div>
                <span>Copyright {{ new Date().getFullYear() }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useConfigurationStore } from '@/store';

export default defineComponent({
    setup: () => ({
        configurationStore: useConfigurationStore(),
        v$: useVuelidate(),
    }),
    data() {
        return {
            email: '',
            submitted: false,
        };
    },
    validations() {
        return {
            email: {
                required,
                email,
            },
        };
    },
    computed: {
        loginColor() {
            return this.configurationStore.isDarkMode ? 'ondark' : 'onlight';
        },
    },
    methods: {},
});
</script>

<style scoped>
.login-body .login-panel .login-appname {
    width: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.login-body .login-panel {
    width: unset !important;
}

.field {
    text-align: left;
}
.login-footer .login-footer-logo-container span {
    margin-right: 0.3rem;
}
@media (max-width: 991px) {
    .login-body .login-panel {
        width: 100% !important;
    }
}
</style>
