<!-- <template>
    <div id="layout-config">
        <a id="layout-config-button" class="layout-config-button" data-v-step="0" @click="onConfigButtonClick($event)">
            <FontAwesomeIcon icon="hand-pointer" size="lg" class="fa-shake" :style="{ color: iconColor }" />
        </a>
        <div class="layout-config" :class="{ 'layout-config-active': configActive }" @click="onConfigClick">
            <div class="grid">
                <div class="col-12" v-for="_establishment in userEstablishments" :key="_establishment._id">
                    <Button
                        type="button"
                        class="p-button-raised w-full btn-establishment flex-column"
                        :class="{
                            'p-button-secondary': !_establishment.active,
                            'p-button-success': _establishment.active,
                        }"
                        @click.prevent="onHotelSelected(_establishment._id)"
                    >
                      <div>
                        <img alt="logo" :src="_establishment.logo" class="bg-white w-full"/>
                      </div>
                      <div class="ml-auto mr-auto font-bold">{{ _establishment.name }}</div>
                    </Button>
                </div>
                <hr />
            </div>
        </div>
    </div>
</template> -->

<script lang="ts">
import { defineComponent } from 'vue';
import { useEstablishmentStore, useConfigurationStore } from '@/store';

type AppConfigComponentState = {
    userEstablishments: Array<any>;
};

export default defineComponent({
    name: 'AppConfig',
    emits: ['config-button-click', 'config-click'],
    props: {
        configActive: {
            type: Boolean,
            default: null,
        },
        configClick: {
            type: Boolean,
            default: null,
        },
    },
    setup() {
        const establishmentStore = useEstablishmentStore();
        const configurationStore = useConfigurationStore();
        return { establishmentStore, configurationStore };
    },
    data() {
        return {
            userEstablishments: [],
        } as AppConfigComponentState;
    },
    computed: {
        iconColor() {
            return this.configurationStore.isDarkMode ? '#2b2f3c' : 'white';
        },
    },
    methods: {
        onConfigButtonClick(event: { preventDefault: () => void }) {
            this.$emit('config-button-click', event);
            event.preventDefault();
        },
        onConfigClick(event: any) {
            this.$emit('config-click', event);
        },
        onHotelSelected(establishmentId: string) {
            this.establishmentStore.setEstablishmentSelected(establishmentId);
        },
    },
    mounted() {
        this.userEstablishments = this.establishmentStore.getEstablishmentsToShow;
        this.establishmentStore.$subscribe(() => {
            this.userEstablishments = this.establishmentStore.getEstablishmentsToShow;
        });
    },
});
</script>

<style scoped></style>
