<script lang="ts">
import { defineComponent } from 'vue';
import PreviewMailEditor from '@/custom-components/smart-mailing/PreviewMailEditor.vue';
import { useMailTemplateStore } from '@/store/mail-templates/mail-templates.store';
import { IMailTemplatePublicPreviewState } from '@/components/smart-mailing/mail-templates/MailTemplatePublicPreview.interface';
import { HttpStatusCode } from 'axios';
import { LocationQueryValue } from 'vue-router';

export default defineComponent({
    name: 'MailTemplatePublicPreview',
    components: { PreviewMailEditor },
    setup: () => {
        const mailTemplateStore = useMailTemplateStore();
        return { mailTemplateStore };
    },
    data(): IMailTemplatePublicPreviewState {
        return {
            showPreview: false,
            html: null,
        };
    },
    methods: {
        async loadMailTemplateHtml(
            companyId: string | null | LocationQueryValue | LocationQueryValue[],
            establishmentId: string | null | LocationQueryValue | LocationQueryValue[],
            mailTemplateId: string | null | LocationQueryValue | LocationQueryValue[],
            signature: string | null | undefined | LocationQueryValue | LocationQueryValue[],
        ) {
            if (!companyId || !establishmentId || !mailTemplateId || !signature) {
                this.$router.push({ name: 'not-found' });
                return;
            }

            const response = await this.mailTemplateStore.getPublicHtml(
                companyId,
                establishmentId,
                mailTemplateId,
                signature,
            );

            if (response.status === HttpStatusCode.Ok && response.data) {
                this.html = response.data?.html;
                await this.$nextTick();
                this.showPreview = true;
            } else if (response.status === HttpStatusCode.Unauthorized) {
                this.$router.push({ name: 'access-denied' });
            } else {
                this.$router.push({ name: 'not-found' });
            }
        },
    },
    async mounted() {
        await this.loadMailTemplateHtml(
            this.$route?.query?.c,
            this.$route?.query?.e,
            this.$route?.params?.id,
            this.$route?.query?.s,
        );
    },
});
</script>

<template>
    <div class="flex flex-row flex-wrap h-screen w-screen align-items-center">
        <PreviewMailEditor v-if="showPreview" :html="html" />
    </div>
</template>

<style scoped></style>
