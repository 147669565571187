import axios, { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { SurveyFormTypeEnum } from '@/core/enums/survey-form-type.enum';

export default class IdsMetriguestServices {
    async getCommentsByDates(from: Date, to: Date, answeredDirector = false, language, note = null, answerType = null) {
        return axios
            .post(`/ids/metriguest/comments/filtered`, {
                from: format(from, 'yyyy-MM-dd'),
                to: format(to, 'yyyy-MM-dd'),
                onlyUnansweredByDirector: answeredDirector,
                guestLanguage: language,
                noteType: note,
                guestAnswerType: answerType,
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getMostRecentRespondedCommentsWithOldStaysPaginated(
        answeredDirector = false,
        language,
        note = null,
        answerType = null,
    ) {
        return axios
            .post(`/ids/metriguest/comments/all/filtered`, {
                page: 1,
                resultsByPage: 2000,
                onlyUnansweredByDirector: answeredDirector,
                guestLanguage: language,
                noteType: note,
                guestAnswerType: answerType,
            })
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async sendDirectorAnswer(data) {
        return axios
            .post(`/ids/metriguest/send/director-answer`, data)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getAllMailsStatsByDates(from: Date, to: Date, surveyFormType: SurveyFormTypeEnum) {
        return axios
            .get(
                `/ids/metriguest/stats/mails/all/${surveyFormType}/${format(from, 'yyyy-MM-dd')}/${format(
                    to,
                    'yyyy-MM-dd',
                )}`,
            )
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async generateResponseReviewFaster(surveyFormType: SurveyFormTypeEnum, surveyId: string) {
        return axios
            .get(`/ids/metriguest/generate/response/review/0/${surveyFormType}/${surveyId}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async generateResponseReviewBetter(surveyFormType: SurveyFormTypeEnum, surveyId: string) {
        return axios
            .get(`/ids/metriguest/generate/response/review/1/${surveyFormType}/${surveyId}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
