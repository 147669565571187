import axios from 'axios';

export default class AuthServices {
    async logIn(username: string, password: string) {
        return await axios
            .post('/auth/login/', {
                username: username,
                password: password,
            })
            .then((res) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err) => {
                return {
                    status: err.response.status,
                    statusText: err.response.statusText,
                    data: null,
                };
            });
    }

    async refreshToken(refreshToken: string | null) {
        return axios
            .post('/auth/refresh', {
                refreshToken: refreshToken,
            })
            .then((res) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err) => {
                return {
                    status: err.response.status,
                    statusText: err.response.statusText,
                    data: null,
                };
            });
    }
}
