<template>
    <Toast />
    <Login v-if="$route.path === '/login'" />
    <ForgotPassword v-else-if="$route.path === '/forgot'" />
    <ResetPassword v-else-if="$route.path === '/reset'" />
    <NotFound v-else-if="$route.path === '/not-found'" />
    <AccessDenied v-else-if="$route.path === '/access-denied'" />
    <PostSurvey v-else-if="$route.path === '/post-survey-forms'" />
    <MailTemplatePublicPreview v-else-if="$route.name === 'mail-template-public-preview'" />
    <App
        v-else
        :theme="theme"
        :color-scheme="colorSchema"
        :component-theme="componentTheme"
        @change-dark-mode="onChangeDarkMode"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import App from '@/App.vue';
import NotFound from '@/pages/NotFound.vue';
import Login from '@/pages/Login.vue';
import ResetPassword from '@/pages/ResetPassword.vue';
import ForgotPassword from '@/pages/ForgotPassword.vue';
import AccessDenied from '@/pages/AccessDenied.vue';
import printSecurityConsiderations from '@/core/start-up-security';

import { useConfigurationStore } from '@/store';
import PostSurvey from '@/pages/PostSurvey.vue';
import MailTemplatePublicPreview from '@/components/smart-mailing/mail-templates/MailTemplatePublicPreview.vue';

export default defineComponent({
    setup: () => {
        const configurationStore = useConfigurationStore();
        return { configurationStore };
    },
    data() {
        return {
            theme: 'red',
            componentTheme: 'red',
        };
    },
    methods: {
        onChangeColorScheme(scheme: any) {
            this.changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
            this.changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        },
        onChangeMenuTheme(theme: any) {
            const layoutLink = document.getElementById('layout-css');
            const href = 'layout/css/' + theme + '/layout-' + this.colorSchema + '.css';

            this.replaceLink(layoutLink, href, null);
            this.theme = theme;
        },
        onChangeComponentTheme(theme: any) {
            const themeLink = document.getElementById('theme-css');
            const href = 'theme/' + theme + '/theme-' + this.colorSchema + '.css';

            this.replaceLink(themeLink, href, null);
            this.componentTheme = theme;
        },
        onChangeDarkMode(color: string, schema: string) {
            document.documentElement.setAttribute('data-theme', schema);
            const layoutLink = document.getElementById('layout-css');
            const hrefLayout = 'layout/css/' + color + '/layout-' + schema + '.css';
            const themeLink = document.getElementById('theme-css');
            const hrefTheme = 'theme/' + color + '/theme-' + schema + '.css';
            this.replaceLink(layoutLink, hrefLayout, null);
            this.replaceLink(themeLink, hrefTheme, null);
        },
        changeStyleSheetUrl(id: any, value: any, from: any) {
            const element = document.getElementById(id);
            if (element) {
                const aa = element.getAttribute('href');
                if (aa) {
                    const urlTokens = aa.split('/');

                    if (from === 1) {
                        // which function invoked this function - change scheme
                        urlTokens[urlTokens.length - 1] = value;
                    } else if (from === 2) {
                        // which function invoked this function - change color
                        urlTokens[urlTokens.length - 2] = value;
                    }

                    const newURL = urlTokens.join('/');

                    this.replaceLink(element, newURL, null);
                }
            }
        },
        replaceLink(linkElement: any, href: any, callback: any) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        },
    },
    computed: {
        colorSchema() {
            return this.configurationStore.isDarkMode ? 'dark' : 'light';
        },
    },
    beforeMount() {
        if (this.configurationStore.isDarkMode) {
            this.onChangeDarkMode('teal', 'dark');
        } else {
            this.onChangeDarkMode('blue', 'light');
        }
    },
    mounted() {
        //printSecurityConsiderations();
    },
    components: {
        MailTemplatePublicPreview,
        PostSurvey: PostSurvey,
        App: App,
        Login: Login,
        ResetPassword: ResetPassword,
        ForgotPassword,
        NotFound: NotFound,
        AccessDenied: AccessDenied,
    },
});
</script>

<style scoped></style>
