<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img
                data-v-step="2"
                id="footer-logo"
                :src="'/layout/images/logo-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                alt="atlantis-layout"
            />
            <span class="app-name">METRIGUEST</span>
        </div>
        <statuspage-widget class="hidden md:flex" src="https://metriguest.statuspage.io"></statuspage-widget>
        <span class="copyright">&#169; Undercoverlab - {{ todayFullYear }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        colorScheme: String,
    },
    computed: {
        todayFullYear() {
            return new Date().getFullYear();
        },
    },
});
</script>

<style scoped>
#footer-logo {
    height: unset;
}
</style>
