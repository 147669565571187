import { defineStore } from 'pinia';
import { useEstablishmentStore } from '@/store/establishment.store';
import AuthServices from '@/service/authentication/authentication.services';

type IUserObject = {
    _id: string;
    username: string;
    password: string;
    name: string;
    lastname: string;
    state: number;
    company: {
        _id: string;
    };
    profilePicture: string;
    lastLogin: {
        _id: string;
        user: string;
        accessObject: {
            ip: string;
            timestamp: string;
            userAgent: string;
            ipInfo: {
                message: string;
            };
        };
    };
    establishments: [
        {
            establishment: {
                _id: string;
                modules: [{ code: number }];
                subModules: [{ code: number }];
            };
            role: {
                code: number;
            };
        },
    ];
};

export type AuthStoreState = {
    userToken: null | string;
    userRefreshToken: null | string;
    userObject: IUserObject | null;
    authenticated: boolean;
    unauthorizedMessage: string;
};

export const useAuthStore = defineStore({
    id: 'mv_auth',
    state: () =>
        ({
            userToken: '',
            userRefreshToken: '',
            userObject: null,
            authenticated: false,
            unauthorizedMessage: '',
        }) as AuthStoreState,
    getters: {
        isAuthenticated: (state: AuthStoreState) => state.authenticated,
        getUserToken: (state: AuthStoreState) => state.userToken,
        getUserRefreshToken: (state: AuthStoreState) => state.userRefreshToken,
        getUnauthorizedMessage: (state: AuthStoreState) => state.unauthorizedMessage,
        getRolesByEstablishment: (state: AuthStoreState) => {
            return (establishmentId: string | null): Array<any> | null => {
                return state.userObject
                    ? state.userObject.establishments
                          .filter((e) => e.establishment._id === establishmentId)
                          .map((e) => e.role.code)
                    : null;
            };
        },
        getModulesByEstablishment: (state: AuthStoreState) => {
            return (establishmentId: string | null): Array<any> | null => {
                return state.userObject
                    ? state.userObject.establishments
                          .filter((e) => e.establishment._id === establishmentId)
                          .map((e) => e.establishment.modules.map((ee) => ee.code))
                          .flat(2)
                    : null;
            };
        },
        getSubModulesByEstablishment: (state: AuthStoreState) => {
            return (establishmentId: string | null): Array<any> | null => {
                return state.userObject
                    ? state.userObject.establishments
                          .filter((e) => e.establishment._id === establishmentId)
                          .map((e) => e.establishment.subModules.map((ee) => ee.code))
                          .flat(2)
                    : null;
            };
        },
        getUserPictureProfile: (state: AuthStoreState) => {
            return state.userObject?.profilePicture ?? '/layout/images/profile-image.png';
        },
        getUserFullName: (state: AuthStoreState) => {
            return `${state.userObject?.name ?? ''} ${state.userObject?.lastname ?? ''}`;
        },
    },
    actions: {
        setUserAuthenticated() {
            this.authenticated = true;
        },
        setUserToken(userObject: any) {
            this.userToken = userObject['access_token'];
        },
        setUserRefreshToken(userObject: any): void {
            this.userRefreshToken = userObject['refresh_token'];
        },
        setUserObject(userObject: any): void {
            this.userObject = userObject.user;
        },
        async logIn(username: string, password: string) {
            const establishmentStore = useEstablishmentStore();
            const authService = new AuthServices();
            return await authService
                .logIn(username, password)
                .then((res: any) => {
                    this.setUserAuthenticated();
                    this.setUserToken(res.data);
                    this.setUserRefreshToken(res.data);
                    this.setUserObject(res.data);
                    establishmentStore.setEstablishments(res.data.establishments);
                    establishmentStore.setEstablishmentSelected(res.data.establishments[0].establishment._id);
                    return true;
                })
                .catch(() => {
                    return false;
                });
        },
        async refreshToken() {
            const authService = new AuthServices();
            return await authService
                .refreshToken(this.userRefreshToken)
                .then((res: any) => {
                    this.setUserToken(res.data);
                    this.setUserRefreshToken(res.data);
                    return res;
                })
                .catch((err: any) => {
                    return err;
                });
        },
        logOut() {
            this.$reset();
            this.$router.push({ name: 'login' });
        },
    },
    persist: {
        storage: window.sessionStorage,
    },
});
