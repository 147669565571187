import { defineStore } from 'pinia';
import IdsMetriguestServices from '@/service/guest-happiness/metriguest/ids-metriguest.services';
import { SurveyFormTypeEnum } from '@/core/enums/survey-form-type.enum';

export const useIdsMetriguestStore = defineStore({
    id: 'mv_idsMetriguest',
    state: () => ({
        idsMetriguestServices: new IdsMetriguestServices(),
    }),
    actions: {
        async getCommentsByDates(
            from: Date,
            to: Date,
            answeredDirector = false,
            language,
            note = null,
            answerType = null,
        ) {
            return await this.idsMetriguestServices.getCommentsByDates(
                from,
                to,
                answeredDirector,
                language,
                note,
                answerType,
            );
        },

        async getMostRecentRespondedCommentsWithOldStaysPaginated(
            answeredDirector = false,
            language,
            note = null,
            answerType = null,
        ) {
            return await this.idsMetriguestServices.getMostRecentRespondedCommentsWithOldStaysPaginated(
                answeredDirector,
                language,
                note,
                answerType,
            );
        },

        async sendDirectorAnswer(data) {
            return await this.idsMetriguestServices.sendDirectorAnswer(data);
        },

        async getAllMailsStatsByDates(from: Date, to: Date, surveyFormType: SurveyFormTypeEnum) {
            return await this.idsMetriguestServices.getAllMailsStatsByDates(from, to, surveyFormType);
        },

        async generateResponseReviewFaster(surveyFormType: SurveyFormTypeEnum, surveyId: string) {
            return await this.idsMetriguestServices.generateResponseReviewFaster(surveyFormType, surveyId);
        },

        async generateResponseReviewBetter(surveyFormType: SurveyFormTypeEnum, surveyId: string) {
            return await this.idsMetriguestServices.generateResponseReviewBetter(surveyFormType, surveyId);
        },
    },
});
