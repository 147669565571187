import axios, { AxiosError, AxiosResponse } from 'axios';
import { ISurveyForm } from '@/components/guest-happiness/email-editor/SurveyEditor.interface';

export default class SurveyEditorServices {
    async getSurveyForms(page: number, resultsPerPage: number) {
        return axios
            .get(`/survey-form/${page}/${resultsPerPage}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getSurveyForm(surveyFormId: string) {
        return axios
            .get(`/survey-form/${surveyFormId}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async getSurveyFormFiltered() {
        return axios
            .get(`/survey-form/filtered`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async addSurveyForm(surveyForm: ISurveyForm) {
        return axios
            .post(`/survey-form`, surveyForm)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async updateSurveyForm(surveyForm: ISurveyForm) {
        return axios
            .put(`/survey-form`, surveyForm)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }

    async deleteSurveyForm(id: string) {
        return axios
            .delete(`/survey-form/${id}`)
            .then((res: AxiosResponse) => {
                return {
                    status: res.status,
                    statusText: res.statusText,
                    data: res.data,
                };
            })
            .catch((err: AxiosError) => {
                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: null,
                };
            });
    }
}
