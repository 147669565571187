<template>
    <div class="exception-body accessdenied">
        <div class="exception-panel">
            <h1>ACCESS</h1>
            <h3>denied</h3>
            <p>You are not allowed to view this page.</p>
            <router-link to="/">
                <Button type="button" label="Go back to home"></Button>
            </router-link>
        </div>
        <div class="exception-footer">
            <img :src="'/layout/images/logo-' + color + '.png'" class="exception-logo" />
            <img :src="'/layout/images/appname-' + color + '.png'" class="exception-appname" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConfigurationStore } from '@/store';

export default defineComponent({
    setup: () => {
        const configurationStore = useConfigurationStore();
        return { configurationStore };
    },
    computed: {
        color() {
            return !this.configurationStore.isDarkMode ? 'dark' : 'light';
        },
    },
});
</script>

<style scoped></style>
