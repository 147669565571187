import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUserSecret,
    faPhone,
    faSquareH,
    faBuildingCircleCheck,
    faScaleUnbalanced,
    faCircle,
    faBook,
    faUtensils,
    faCodeCompare,
    faCalendar,
    faSquarePollHorizontal,
    faSquarePollVertical,
    faSliders,
    faFaceLaughBeam,
    faM,
    faTableColumns,
    faTimeline,
    faHouse,
    faEyeDropper,
    faFlagCheckered,
    faUsersLine,
    faHammer,
    faEnvelopesBulk,
    faCheckDouble,
    faHandPointer,
    faCommentsDollar,
    faThumbsUp,
    faThumbsDown,
    faCommenting,
    faCommentMedical,
    faCommentSlash,
    faComment,
    faChildren,
    faPersonWalkingLuggage,
    faPeopleGroup,
    faPeopleArrows,
    faGlobe,
    faPersonShelter,
    faUmbrellaBeach,
    faLocationDot,
    faBellConcierge,
    faEnvelopeOpenText,
    faEye,
    faEquals,
    faScroll,
    faBed,
    faPersonHalfDress,
    faBroom,
    faBedPulse,
    faHandHoldingDollar,
    faWifi,
    faCheckCircle,
    faMessage,
    faHouseCircleExclamation,
    faCity,
    faMugSaucer,
    faEuroSign,
    faShapes,
    faBuilding,
    faHeart,
    faSpa,
    faBowlFood,
    faParking,
    faLeaf,
    faDumbbell,
    faToilet,
    faWheelchair,
    faRunning,
    faUsers,
    faGamepad,
    faGlassMartini,
    faBus,
    faMoon,
    faSnowflake,
    faTree,
    faQuestion,
    faMoneyBill1,
    faMoneyBill,
    faSackDollar,
    faWandMagicSparkles,
    faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import { faDotCircle, faFlag } from '@fortawesome/free-regular-svg-icons';
import { faGooglePlus, faAirbnb, faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserSecret);
library.add(faPhone);
library.add(faSquareH);
library.add(faBuildingCircleCheck);
library.add(faScaleUnbalanced);
library.add(faCircle);
library.add(faBook);
library.add(faUtensils);
library.add(faCodeCompare);
library.add(faCalendar);
library.add(faSquarePollHorizontal);
library.add(faSquarePollVertical);
library.add(faSliders);
library.add(faFaceLaughBeam);
library.add(faM);
library.add(faTableColumns);
library.add(faTimeline);
library.add(faHouse);
library.add(faEyeDropper);
library.add(faFlagCheckered);
library.add(faUsersLine);
library.add(faHammer);
library.add(faEnvelopesBulk);
library.add(faCheckDouble);
library.add(faHandPointer);
library.add(faCommentsDollar);
library.add(faThumbsDown);
library.add(faThumbsUp);
library.add(faCommenting);
library.add(faCommentMedical);
library.add(faCommentSlash);
library.add(faComment);
library.add(faChildren);
library.add(faPersonWalkingLuggage);
library.add(faPeopleGroup);
library.add(faPeopleArrows);
library.add(faGlobe);
library.add(faPersonShelter);
library.add(faUmbrellaBeach);
library.add(faLocationDot);
library.add(faBellConcierge);
library.add(faEnvelopeOpenText);
library.add(faEye);
library.add(faEquals);
library.add(faScroll);
library.add(faBed);
library.add(faPersonHalfDress);
library.add(faBroom);
library.add(faBedPulse);
library.add(faHandHoldingDollar);
library.add(faWifi);
library.add(faCheckCircle);
library.add(faMessage);
library.add(faHouseCircleExclamation);
library.add(faCity);
library.add(faBuilding);
library.add(faDotCircle);
library.add(faMugSaucer);
library.add(faEuroSign);
library.add(faShapes);
library.add(faFlag);
library.add(faHeart);
library.add(faSpa);
library.add(faBowlFood);
library.add(faParking);
library.add(faLeaf);
library.add(faDumbbell);
library.add(faToilet);
library.add(faWheelchair);
library.add(faRunning);
library.add(faUsers);
library.add(faGamepad);
library.add(faGlassMartini);
library.add(faBus);
library.add(faMoon);
library.add(faSnowflake);
library.add(faTree);
library.add(faQuestion);
library.add(faMoneyBill1);
library.add(faMoneyBill);
library.add(faSackDollar);
library.add(faWandMagicSparkles);
library.add(faChartBar);

library.add(faGooglePlus);
library.add(faAirbnb);
library.add(faBootstrap);

export default FontAwesomeIcon;
