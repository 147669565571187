import { ModuleEnum, RoleEnum, SubModuleEnum } from '@/core/enums';

export const MenuItems = [
    {
        label: 'Dashboard',
        icon: ['fas', 'house'],
        modules: [ModuleEnum.PERFORMANCE, ModuleEnum.GUEST_HAPPINESS],
        subModules: [
            SubModuleEnum.BOOKINGS,
            SubModuleEnum.BUSINESS_ON_THE_BOOKS,
            SubModuleEnum.AIRBNB_DASHBOARD,
            SubModuleEnum.GOOGLE_DASHBOARD,
            SubModuleEnum.BOOKING_DASHBOARD,
        ],
        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
        items: [
            {
                label: 'Dashboard Single',
                icon: ['fas', 'building'],
                to: '/dashboard-single',
                modules: [ModuleEnum.PERFORMANCE, ModuleEnum.GUEST_HAPPINESS],
                subModules: [
                    SubModuleEnum.BOOKINGS,
                    SubModuleEnum.BUSINESS_ON_THE_BOOKS,
                    SubModuleEnum.AIRBNB_DASHBOARD,
                    SubModuleEnum.GOOGLE_DASHBOARD,
                    SubModuleEnum.BOOKING_DASHBOARD,
                ],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            },
            {
                label: 'Dashboard Multiple',
                icon: ['fas', 'city'],
                to: '/dashboard-multiple',
                modules: [ModuleEnum.PERFORMANCE, ModuleEnum.GUEST_HAPPINESS],
                subModules: [
                    SubModuleEnum.BOOKINGS,
                    SubModuleEnum.BUSINESS_ON_THE_BOOKS,
                    SubModuleEnum.AIRBNB_DASHBOARD,
                    SubModuleEnum.GOOGLE_DASHBOARD,
                    SubModuleEnum.BOOKING_DASHBOARD,
                ],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            },
        ],
    },
    {
        label: 'Performance',
        icon: ['fas', 'scale-unbalanced'],
        modules: [ModuleEnum.PERFORMANCE],
        subModules: [SubModuleEnum.BOOKINGS, SubModuleEnum.BUSINESS_ON_THE_BOOKS],
        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
        items: [
            {
                label: 'Business on the Books',
                icon: ['fas', 'book'],
                to: '/business-on-the-books',
                modules: [ModuleEnum.PERFORMANCE],
                subModules: [SubModuleEnum.BUSINESS_ON_THE_BOOKS],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            },
            {
                label: 'OnSite By Dates',
                icon: ['fas', 'book'],
                to: '/on-site-by-dates',
                modules: [ModuleEnum.PERFORMANCE],
                subModules: [SubModuleEnum.BUSINESS_ON_THE_BOOKS],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            },
            {
                label: 'Bookings',
                icon: ['fas', 'utensils'],
                to: '/bookings',
                modules: [ModuleEnum.PERFORMANCE],
                subModules: [SubModuleEnum.BOOKINGS],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
            },
            {
                label: 'Budgets',
                icon: ['fas', 'sack-dollar'],
                to: '/budgets',
            },
        ],
    },
    {
        label: 'Rate Shopper',
        icon: ['fas', 'code-compare'],
        disabled: true,
        roles: [9999],
        items: [
            {
                label: 'Rate Shopper',
                icon: ['fas', 'square-poll-horizontal'],
                to: '/rate-shopper',
            },
            {
                label: 'Competitors',
                icon: ['fas', 'house-circle-exclamation'],
            },
            {
                label: 'Competitive Set',
                icon: ['fas', 'city'],
            },
        ],
    },
    {
        label: 'Guest Happiness',
        icon: ['fas', 'face-laugh-beam'],
        modules: [ModuleEnum.GUEST_HAPPINESS],
        subModules: [
            SubModuleEnum.METRIGUEST_DASHBOARD,
            SubModuleEnum.METRIGUEST_COMMENT_VIEW,
            SubModuleEnum.METRIGUEST_COMMENT_TIMELINE,
            SubModuleEnum.AIRBNB_DASHBOARD,
            SubModuleEnum.AIRBNB_COMMENT_TIMELINE,
            SubModuleEnum.GOOGLE_DASHBOARD,
            SubModuleEnum.GOOGLE_COMMENT_TIMELINE,
            SubModuleEnum.BOOKING_DASHBOARD,
            SubModuleEnum.BOOKING_COMMENT_TIMELINE,
            SubModuleEnum.EMAIL_EDITOR,
            SubModuleEnum.SURVEY_EDITOR,
        ],
        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
        items: [
            {
                label: 'Metriguest',
                icon: ['fas', 'm'],
                modules: [ModuleEnum.GUEST_HAPPINESS],
                subModules: [
                    SubModuleEnum.METRIGUEST_DASHBOARD,
                    SubModuleEnum.METRIGUEST_COMMENT_VIEW,
                    SubModuleEnum.METRIGUEST_COMMENT_TIMELINE,
                ],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                items: [
                    {
                        label: 'Survey Forms',
                        icon: ['fas', 'fa-square-poll-vertical'],
                        to: '/survey-forms',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.SURVEY_EDITOR],
                        roles: [RoleEnum.Editor, RoleEnum.Company_Editor],
                    },
                    {
                        label: 'Comment Dashboard',
                        icon: ['fas', 'table-columns'],
                        to: '/metriguest-dashboard',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.METRIGUEST_DASHBOARD],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                    {
                        label: 'Timeline By Month',
                        icon: ['fas', 'timeline'],
                        to: '/metriguest-timeline',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.METRIGUEST_COMMENT_TIMELINE],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                    {
                        label: 'Timeline By Comments',
                        icon: ['fas', 'timeline'],
                        to: '/metriguest-timeline-newest-responses',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.METRIGUEST_COMMENT_TIMELINE],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                ],
            },
            {
                label: 'Booking',
                icon: ['fab', 'bootstrap'],
                modules: [ModuleEnum.GUEST_HAPPINESS],
                subModules: [SubModuleEnum.BOOKING_DASHBOARD, SubModuleEnum.BOOKING_COMMENT_TIMELINE],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                items: [
                    {
                        label: 'Comment Dashboard',
                        icon: ['fas', 'table-columns'],
                        to: '/booking-dashboard',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.BOOKING_DASHBOARD],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                    {
                        label: 'Comment Timeline',
                        icon: ['fas', 'timeline'],
                        to: '/booking-timeline',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.BOOKING_COMMENT_TIMELINE],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                ],
            },
            {
                label: 'Airbnb',
                icon: ['fab', 'airbnb'],
                modules: [ModuleEnum.GUEST_HAPPINESS],
                subModules: [SubModuleEnum.AIRBNB_DASHBOARD, SubModuleEnum.AIRBNB_COMMENT_TIMELINE],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                items: [
                    {
                        label: 'Comment Dashboard',
                        icon: ['fas', 'table-columns'],
                        to: '/airbnb-dashboard',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.AIRBNB_DASHBOARD],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                    {
                        label: 'Comment Timeline',
                        icon: ['fas', 'timeline'],
                        to: '/airbnb-timeline',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.AIRBNB_COMMENT_TIMELINE],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                ],
            },
            {
                label: 'Google',
                icon: ['fab', 'google-plus'],
                modules: [ModuleEnum.GUEST_HAPPINESS],
                subModules: [SubModuleEnum.GOOGLE_DASHBOARD, SubModuleEnum.GOOGLE_COMMENT_TIMELINE],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                items: [
                    {
                        label: 'Comment Dashboard',
                        icon: ['fas', 'table-columns'],
                        to: '/google-dashboard',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.GOOGLE_DASHBOARD],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                    {
                        label: 'Comment Timeline',
                        icon: ['fas', 'timeline'],
                        to: '/google-timeline',
                        modules: [ModuleEnum.GUEST_HAPPINESS],
                        subModules: [SubModuleEnum.GOOGLE_COMMENT_TIMELINE],
                        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer],
                    },
                ],
            },
        ],
    },
    {
        label: 'SmartMailing',
        icon: ['fas', 'envelopes-bulk'],
        modules: [ModuleEnum.SMART_MAILING],
        subModules: [
            SubModuleEnum.CAMPAIGNS,
            SubModuleEnum.TEMPLATES,
            SubModuleEnum.CAMPAIGN_EDITOR,
            SubModuleEnum.CAMPAIGN_REPORTS,
            SubModuleEnum.REPORTS,
            SubModuleEnum.EMAIL_BUILDER,
        ],
        roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer, RoleEnum.Company_Editor],
        items: [
            {
                label: 'Mail Templates',
                icon: ['fas', 'wand-magic-sparkles'],
                to: '/mail-templates',
                modules: [ModuleEnum.SMART_MAILING],
                subModules: [SubModuleEnum.TEMPLATES],
                roles: [RoleEnum.Company_Editor, RoleEnum.Editor],
            },
            {
                label: 'Mail Campaigns',
                icon: ['fas', 'flag-checkered'],
                to: '/mail-campaigns',
                modules: [ModuleEnum.SMART_MAILING],
                subModules: [SubModuleEnum.CAMPAIGN_REPORTS],
                roles: [RoleEnum.Editor, RoleEnum.Manager, RoleEnum.Visualizer, RoleEnum.Company_Editor],
            },
        ],
    },
];
