<template>
    <div v-if="eggFound && secret === 0" class="flex justify-content-center align-items-center w-full h-full">
        <img src="https://c.tenor.com/uVZUsEolHw4AAAAd/pacman.gif" />
    </div>
    <div v-else class="login-body">
        <div class="login-image">
            <img src="/layout/images/pages/login-image.jpeg" alt="atlantis" />
        </div>
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'/layout/images/pages/login-logo-' + loginColor + '.png'" class="login-appname" />
                </div>
                <div class="form-container">
                    <div class="field">
                        <span class="p-input-icon-left">
                            <i class="pi pi-user"></i>
                            <InputText
                                type="text"
                                placeholder="Username"
                                v-model="username"
                                :class="{ 'p-invalid': v$.username.$invalid && submitted }"
                            />
                        </span>
                        <small
                            v-if="(v$.username.$invalid && submitted) || v$.username.$pending.$response"
                            class="p-error"
                            >Username is required!</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-input-icon-left">
                            <i class="pi pi-lock"></i>
                            <Password
                                placeholder="Password"
                                :input-style="{ 'padding-left': '2.142rem', 'margin-bottom': 'unset' }"
                                v-model="password"
                                :feedback="false"
                                toggle-mask
                                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                            />
                        </span>
                        <small
                            v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response"
                            class="p-error"
                            >Password is required!</small
                        >
                    </div>
                    <router-link to="/forgot">
                        <a class="flex">Forgot your password?</a>
                    </router-link>
                </div>
                <div class="button-container">
                    <Button
                        type="button"
                        label="Login"
                        :loading="loading"
                        loading-icon="pi pi-spin pi-spinner"
                        @click="handleLogin"
                    ></Button>
                </div>
            </div>

            <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <span>Made with 💖 by</span>
                    <img
                        src="https://www.undercoverlab.com/wp-content/uploads/2020/04/logo_undercoverlab.png"
                        class="login-footer-logo"
                        @click.prevent="onSecretIconClicked"
                    />
                </div>
                <span>Copyright {{ new Date().getFullYear() }}</span>
            </div>
            <div class="div-status-widget">
                <statuspage-widget src="https://metriguest.statuspage.io"></statuspage-widget>
            </div>
        </div>
    </div>
    <Dialog
        v-if="secret === 10"
        :visible="secret === 10"
        :closable="false"
        :style="{ width: '70vw' }"
        position="bottomright"
        :modal="true"
    >
        <Terminal welcomeMessage="This is a private Metriguest - System terminal" :prompt="terminalPrompt" />
    </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TerminalService from 'primevue/terminalservice';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useAuthStore, useConfigurationStore, useEstablishmentStore } from '@/store';
import { SubModuleEnum } from '@/core/enums';

export default defineComponent({
    setup: () => {
        const configurationStore = useConfigurationStore();
        const establishmentStore = useEstablishmentStore();
        const authStore = useAuthStore();
        const v$ = useVuelidate();
        return { v$, authStore, configurationStore, establishmentStore };
    },
    data() {
        return {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            secret: 0,
            terminalPrompt: '[root]$> ',
            eggFound: false,
        };
    },
    validations() {
        return {
            username: {
                required,
            },
            password: {
                required,
            },
        };
    },
    computed: {
        loginColor() {
            return this.configurationStore.isDarkMode ? 'ondark' : 'onlight';
        },
    },
    methods: {
        async handleLogin() {
            this.submitted = true;
            const validationResult = await this.v$.$validate();
            if (validationResult) {
                this.loading = true;
                const _loginResponse: any = await this.authStore.logIn(this.username, this.password);
                if (_loginResponse) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Authentication successfully',
                        detail: 'Enjoy it ♥️',
                        life: 5000,
                    });
                    const __subModules =
                        this.authStore.getSubModulesByEstablishment(this.establishmentStore.getEstablishmentSelected) ??
                        [];
                    if (__subModules.includes(SubModuleEnum.BOOKING_DASHBOARD)) {
                        setTimeout(() => {
                            this.$router.push('/dashboard-single');
                        }, 100);
                    } else {
                        setTimeout(() => {
                            this.$router.push('/');
                        }, 100);
                    }
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Authentication failed',
                        detail: _loginResponse.statusText,
                        life: 5000,
                    });
                }
                this.loading = false;
            }
        },
        onSecretIconClicked() {
            this.secret++;
            if (this.secret === 10) {
                setTimeout(() => {
                    this.secret = 0;
                }, 500000);
            }
        },
        commandHandler(text: string) {
            let response;
            const argsIndex = text.indexOf(' ');
            const command = argsIndex !== -1 ? text.substring(0, argsIndex) : text;

            if (this.terminalPrompt === 'password: ') {
                if (command === 'm3tr1gu3st_sup3r_s3cr3t_') {
                    this.terminalPrompt = '[root]$> ';
                    TerminalService.emit('response', 'EasterEgg found 🐣');
                    TerminalService.off('command', this.commandHandler);
                    setTimeout(() => {
                        this.secret = 0;
                        this.eggFound = true;
                        setTimeout(() => window.location.reload(), 7000);
                    }, 3000);
                    return;
                }
            }

            switch (command) {
                case 'whoami':
                    response = '¿Pacman? 👾 🤔';
                    break;
                case 'help':
                    response = `help - whoami - secret - auth - piste`;
                    break;
                case 'secret':
                    response = 'Are you sure? ==> bTN0cjFndTNzdF9zdXAzcl9zM2NyM3Rf';
                    break;
                case 'auth':
                    this.terminalPrompt = 'password: ';
                    break;
                case 'piste':
                    response = 'Try CyberChef with magic recipe 😉';
                    break;
                default:
                    response = 'Unknown command: ' + command;
            }

            TerminalService.emit('response', response);
        },
    },
    mounted() {
        this.establishmentStore.$reset();
        TerminalService.on('command', this.commandHandler);
    },
    beforeUnmount() {
        TerminalService.off('command', this.commandHandler);
    },
});
</script>

<style scoped>
.login-body .login-panel .login-appname {
    width: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.login-body .login-panel {
    width: unset !important;
}

.field {
    text-align: left;
}

.login-footer .login-footer-logo-container span {
    margin-right: 0.3rem;
}

.div-status-widget {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
}

@media (max-width: 991px) {
    .login-body .login-panel {
        width: 100% !important;
    }
}
</style>

<style>
.p-terminal {
    background-color: black;
}
.p-terminal-prompt {
    color: yellow;
}
.p-terminal-response {
    color: deeppink;
}
.p-terminal-input {
    font-size: 1.5rem !important;
    margin-left: 0.3rem;
}

.p-terminal.p-component {
    font-size: 1.5rem;
}

.p-password > svg {
    right: 0 !important;
    margin-right: 0.5rem !important;
}
</style>
