<template>
    <div :class="layoutContainerClass" @click="onDocumentClick">
        <div class="layout-main">
            <AppTopBar
                :items="menuItems"
                :menu-mode="menuMode"
                :color-scheme="colorScheme"
                :menu-active="menuActive"
                :topbar-menu-active="topbarMenuActive"
                :active-inline-profile="activeInlineProfile"
                @topbar-item-click="onTopbarItemClick"
                @menubutton-click="onMenuButtonClick"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
                @change-dark-mode="onChangeDarkMode"
                @send-comments-click="onSendCommentsClick"
            />

            <AppMenu
                :model="menuItems"
                :menu-mode="menuMode"
                :color-scheme="colorScheme"
                :menu-active="menuActive"
                :sidebar-active="sidebarActive"
                :sidebar-static="sidebarStatic"
                :pin-active="pinActive"
                :active-inline-profile="activeInlineProfile"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
                @change-dark-mode="onChangeDarkMode"
                @send-comments-click="onSendCommentsClick"
            />

            <AppBreadcrumb
                :menu-mode="menuMode"
                v-model:searchActive="searchActive"
                v-model:searchClick="searchClick"
                @menubutton-click="onMenuButtonClick"
                @rightmenu-button-click="onRightMenuButtonClick"
                :config-active="configActive"
                :config-click="configClick"
                @config-button-click="onConfigButtonClick"
                @config-click="onConfigClick"
            />

            <div class="layout-main-content">
                <router-view v-slot="{ Component, route }">
                    <transition name="fade">
                        <div :key="route.name">
                            <component v-if="route.meta?.static && route.meta.static === true" :is="Component" />
                            <component v-else :is="Component" :key="establishmentStore.getEstablishmentSelected" />
                        </div>
                    </transition>
                </router-view>
            </div>

            <AppFooter :color-scheme="colorScheme" />
        </div>

        <AppRightMenu
            :right-menu-active="rightMenuActive"
            @rightmenu-click="onRightMenuClick"
            @rightmenu-active="onChangeRightMenuActive"
        />

        <AppConfig
            :config-active="configActive"
            :config-click="configClick"
            @config-button-click="onConfigButtonClick"
            @config-click="onConfigClick"
        />
    </div>
    <ContactSupportForm
        v-model:visible="showDialogSendComments"
        @click-close-send-comments="onClickCloseSendComments"
    />
    <Dialog
        :visible="showTourDialog"
        @update:visible="hideTourDialogHandler"
        :breakpoints="{ '640px': '100vw' }"
        :style="{ width: '50vw' }"
        :position="'left'"
        :modal="true"
        :dismissable-mask="true"
        :close-on-escape="true"
    >
        <template #header>
            <h4>¡New user tour!</h4>
        </template>
        <p class="m-0">Do you want to start a tiny tour of the new super amazing metriguest? 🙄</p>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="dismissNewUserPageTour" />
            <Button label="Yes" icon="pi pi-check" autofocus @click="startNewUserPageTour" />
        </template>
    </Dialog>
    <v-tour name="newUserTour" :steps="tourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppTopBar from './layout/AppTopbar.vue';
import AppMenu from './layout/AppMenu.vue';
import AppRightMenu from './layout/AppRightMenu.vue';
import AppFooter from './layout/AppFooter.vue';
import AppBreadcrumb from '@/layout/AppBreadcrumb.vue';
import AppConfig from './layout/AppConfig.vue';
import EventBus from './core/event-bus';
import { useAuthStore, useConfigurationStore, useEstablishmentStore } from './store';
import { MenuItems } from '@/core/menu';
import { TourSteps } from './core/tour';
import { RoleEnum, SubModuleEnum } from '@/core/enums';
import ContactSupportForm from '@/custom-components/support/contact-support-form.vue';

export default defineComponent({
    emits: [
        'layout-mode-change',
        'menu-theme',
        'layoutModeChange',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'change-component-theme',
        'change-menu-theme',
        'change-inlinemenu',
        'change-dark-mode',
    ],
    props: {
        colorScheme: String,
        toparTheme: String,
        theme: String,
        componentTheme: String,
    },
    setup() {
        const authStore = useAuthStore();
        const configurationStore = useConfigurationStore();
        const establishmentStore = useEstablishmentStore();
        return { authStore, configurationStore, establishmentStore };
    },
    data() {
        return {
            menuClick: false,
            search: false,
            searchClick: false,
            searchActive: false,
            menuMode: 'sidebar',
            menuActive: false,
            activeInlineProfile: false,
            inlineMenuClick: false,
            overlayMenuActive: false,
            topbarMenuActive: false,
            topbarItemClick: false,
            sidebarActive: false,
            sidebarStatic: false,
            pinActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menuItems: MenuItems,
            showTourDialog: false,
            tourSteps: TourSteps,
            tourCallbacks: {
                onFinish: this.onTourFinish,
            },
            showDialogSendComments: false,
        };
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        dismissNewUserPageTour() {
            this.showTourDialog = false;
            this.configurationStore.changeTourState(true);
        },
        startNewUserPageTour() {
            this.showTourDialog = false;
            if (this.isDesktop()) {
                this.$tours['newUserTour'].start();
            }
        },
        hideTourDialogHandler(value) {
            this.showTourDialog = value;
        },
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.topbarItemClick) {
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    EventBus.emit('reset-active-index');
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.overlayMenuActive = false;
                    this.staticMenuMobileActive = false;
                }

                this.hideOverlayMenu();
                this.unblockBodyScroll();
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.configClick = false;
            this.rightMenuClick = false;
            this.searchClick = false;
            this.inlineMenuClick = false;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onSidebarMouseOver() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                this.sidebarActive = this.isDesktop();
                setTimeout(() => {
                    this.pinActive = this.isDesktop();
                }, 200);
            }
        },
        onSidebarMouseLeave() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.sidebarActive = false;
                    this.pinActive = false;
                }, 250);
            }
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        onMenuButtonClick(event: Event) {
            this.menuClick = true;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }

            event.preventDefault();
        },
        onTopbarItemClick(event: Event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onRightMenuButtonClick() {
            this.rightMenuClick = true;
            this.rightMenuActive = true;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuItemClick(event: any) {
            if (!event.item.items) {
                this.hideOverlayMenu();
                EventBus.emit('reset-active-index');
            }

            if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                this.menuActive = false;
            }
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick(event: Event) {
            this.configActive = !this.configActive;
            this.configClick = true;
            event.preventDefault();
        },
        onChangeRightMenuActive(active: boolean) {
            this.rightMenuActive = active;
        },
        onChangeDarkMode(color: string, scheme: string) {
            this.$emit('change-dark-mode', color, scheme);
        },
        onSendCommentsClick() {
            this.showDialogSendComments = true;
        },
        onClickCloseSendComments() {
            this.showDialogSendComments = false;
        },
        onToggleMenu(event: Event) {
            this.menuClick = true;

            if (this.overlayMenuActive) {
                this.overlayMenuActive = false;
            }

            if (this.sidebarActive) {
                this.sidebarStatic = !this.sidebarStatic;
            }

            event.preventDefault();
        },
        onChangeActiveInlineMenu() {
            this.activeInlineProfile = !this.activeInlineProfile;
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(
                    new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
                    ' ',
                );
            }
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        onTourFinish() {
            this.configurationStore.changeTourState(true);
        },
        reconstructMenuItems(items, modules, subModules, roles) {
            return items.map((item) => {
                const newItem = { ...item, visible: true };

                if (item.items !== undefined) {
                    newItem.items = this.reconstructMenuItems(item.items, modules, subModules, roles);
                }

                if (item.modules !== undefined && Array.isArray(item.modules) && item.modules.length) {
                    newItem.visible = newItem.visible && modules.some((module) => item.modules.includes(module));
                }

                if (item.subModules !== undefined && Array.isArray(item.subModules) && item.subModules.length) {
                    newItem.visible =
                        newItem.visible && subModules.some((subModule) => item.subModules.includes(subModule));
                }

                if (item.roles !== undefined && Array.isArray(item.roles) && item.roles.length) {
                    newItem.visible = newItem.visible && roles.some((role) => item.roles.includes(role));
                }

                return newItem;
            });
        },
    },
    computed: {
        layoutContainerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-static': this.menuMode === 'static',
                    'layout-overlay': this.menuMode === 'overlay',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-slim': this.menuMode === 'slim',
                    'layout-horizontal': this.menuMode === 'horizontal',
                    'layout-active': this.menuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-sidebar': this.menuMode === 'sidebar',
                    'layout-sidebar-static': this.menuMode === 'sidebar' && this.sidebarStatic,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
            ];
        },
    },
    async mounted() {
        if (this.isDesktop()) {
            this.showTourDialog = !this.configurationStore.isTourEnded;
        }
        const _establishmentSelected = this.establishmentStore.establishmentSelected;
        if (_establishmentSelected) {
            await this.$nextTick();
            const _establishmentModules = this.authStore.getModulesByEstablishment(_establishmentSelected);
            const _establishmentSubModules = this.authStore.getSubModulesByEstablishment(_establishmentSelected);
            const _establishmentRoles = this.authStore.getRolesByEstablishment(_establishmentSelected);
            this.menuItems = this.reconstructMenuItems(
                this.menuItems,
                _establishmentModules,
                _establishmentSubModules,
                _establishmentRoles,
            );
        }
        this.establishmentStore.$subscribe((mutation, state) => {
            if (mutation.events['key'] && mutation.events['key'] === 'establishmentSelected') {
                const _establishmentModules = this.authStore.getModulesByEstablishment(state.establishmentSelected);
                const _establishmentSubModules: SubModuleEnum[] | null = this.authStore.getSubModulesByEstablishment(
                    state.establishmentSelected,
                );
                const _establishmentRoles: RoleEnum[] | null = this.authStore.getRolesByEstablishment(
                    state.establishmentSelected,
                );
                this.menuItems = this.reconstructMenuItems(
                    this.menuItems,
                    _establishmentModules,
                    _establishmentSubModules,
                    _establishmentRoles,
                );
                if (this.$route.meta?.subModules && _establishmentSubModules) {
                    const currentSubModules: SubModuleEnum[] = this.$route.meta.subModules;
                    if (!_establishmentSubModules.some((_subModule) => currentSubModules.includes(_subModule))) {
                        this.$router.replace('/access-denied');
                    }
                }
                if (this.$route.meta?.roles && _establishmentRoles) {
                    const currentRoles: RoleEnum[] = this.$route.meta.roles;
                    if (!_establishmentRoles.some((_role) => currentRoles.includes(_role))) {
                        this.$router.replace('/access-denied');
                    }
                }
            }
        });
    },
    components: {
        AppTopBar,
        AppMenu,
        AppRightMenu,
        AppFooter,
        AppBreadcrumb,
        AppConfig,
        ContactSupportForm,
    },
});
</script>
